import { capitalise } from "./helper";

// constant strings //
export const PRIMARYCOLOR = "#4DD0E1";
export const SECONDARYCOLOR = "#1D2433";
export const GREYCOLOR = "#FAFAFA";
export const REDBG = "#FDEAEA";
export const RED = "#EF4444";
export const WHITE = "WHITE";
export const HRLINE = "E1E6EF";
export const LIGHTBOXSHADOW = "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px";
export const NAME = "name";
export const TEXT = "TEXT";
export const EMAIL = "email";
export const PASSWORD = "password";
export const DESIGNATION = "designation";
export const ORGANIZATION = "organisation_name";

// constant objects //

export const nameField = {
  name: NAME,
  type: TEXT,
  label: capitalise(NAME),
  required: false,
  placeholder: capitalise(NAME),
};

export const emailField = {
  name: EMAIL,
  type: EMAIL,
  label: capitalise(EMAIL),
  required: false,
  placeholder: capitalise(EMAIL),
};

export const passwordField = {
  name: PASSWORD,
  type: PASSWORD,
  label: capitalise(PASSWORD),
  placeholder: capitalise(PASSWORD),
  required: false,
};

export const organizationName = {
  name: ORGANIZATION,
  type: TEXT,
  label: capitalise("Organization"),
  placeholder: capitalise("Organization"),
  required: false,
};

export const designationField = {
  name: DESIGNATION,
  type: TEXT,
  label: capitalise('Designation'),
  placeholder: capitalise('Designation'),
  required: false,
};

export const surveyForm = {
  end_date: "2024-08-30",
  start_date: "2024-08-12",
  location_id: 1,
  organisation_id: 1,
  language: 1,
  thumbnail_id: 1,
  title: "Survey 2",
};
