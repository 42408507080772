import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DateField.module.css";
import { styled } from "@mui/material/styles";
import cal from "../../../../assets/componentAssest/cal.svg";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { pad } from "crypto-js";
const StyledDatePickerWrapper = styled("div")(({ theme }) => ({
  ".react-datepicker-wrapper": {
    width: "100%",
  },

  ".react-datepicker": {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  ".react-datepicker__header": {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__current-month, .react-datepicker__day-name": {
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected":
    {
      backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.contrastText,
      color: "#fff",
    },
  ".react-datepicker__day--today": {
    fontWeight: "bold",
  },
  ".react-datepicker__day:hover": {
    backgroundColor: theme.palette.primary.dark,
    // color: theme.palette.primary.contrastText,
    color: "#fff",
  },
  ".react-datepicker__input-container input": {
    width: "100%",
    height: "40px",
    padding: "0 12px",
    fontSize: "16px",
    borderRadius: "4px",
    // border: `1px solid rgba(0,0,0, 0.23)`,
    // border:"none",
    color: theme.palette.text.primary,
    "&:focus": {
      borderColor: theme.palette.primary.main,
      outline: "none",
    },
  },
  ".react-datepicker__input-container input:hover": {
    // border: `1px solid rgba(0,0,0, 0.83)`,
    // border:"none",
  },
  ".react-datepicker-popper": {
    zIndex: `15`,
    lineHeight: `0`,
  },
  ".react-datepicker__input-container .react-datepicker__calendar-icon": {
    position: `absolute`,
    padding: `0.7rem 0.4rem`,
    boxSizing: `content-box`,
  },
  ".css-1c142pm-MuiInputBase-root-MuiOutlinedInput-root": {
    padding: `0px`,
  },
}));
const CustomInput = ({ value, onClick, placeHolder }) => (
  <div className={styles.customDateInput}>
    <TextField
      className={styles.datePickerInput}
      style={{ width: "90%" }}
      value={value}
      onClick={onClick}
      placeholder={placeHolder}
      readOnly
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} sx={{ width: "10%" }}>
              <img src={cal} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </div>
);
const DateField = ({
  onChange,
  disabled = false,
  startDate = new Date(),
  placeHolder,
  ...props
}) => {
  return (
    <StyledDatePickerWrapper>
      <DatePicker
        // className={styles.datePicker}
        // showIcon
        dateFormat="d MMM yyyy"
        selected={startDate}
        onChange={onChange}
        customInput={<CustomInput placeHolder={placeHolder} />}
        {...props}
        disabled={disabled}
      />
    </StyledDatePickerWrapper>
  );
};

export default DateField;
