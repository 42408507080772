import React, { useContext, useEffect, useState } from "react";
import DataTable from "../../Components/DataTable/DataTable";
import { getApi, PatchApi } from "../../Utility/network";
import styles from "./DiagnosticsManagement.module.css";
import ShareSurveyPopUp from "../../Components/ShareSurveyPopUp/ShareSurveyPopUp";
import { useNavigate } from "react-router-dom";
import DateField from "../Dashboard/Components/DateField/DateField";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
function DiagnosticsManagement() {
  const navigate = useNavigate();
  const { contextState, orgId } = useContext(AppContext);

  const [open, setOpen] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [tdata, setTData] = useState();
  const [respondent, setRespondent] = useState();
  const [id, setId] = useState(null);
  const [data, setData] = useState([]);
  const [invited, setInvited] = useState(null);
  const [change, setChange] = useState(null);
  const start_date = new Date();

  const formattedDate =
    start_date.getFullYear() +
    "-" +
    String(start_date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(start_date.getDate()).padStart(2, "0");
  const [form, setForm] = useState({
    start_date: "",
    end_date: "",
  });

  console.log(form, "respondent");
  const handleView = (data) => {
    console.log(data, "data");
  };
  const handleDialog = (data) => {
    setOpenDialog(true);
    setRespondent(data?.total_invited_respondents);
    setId(data?.id);
    getShareSurvey(data?.id);

    console.log(data, "data");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };
  const getDignostics = async (filter, orgId) => {
    if (!orgId) {
      return;
    }
    try {
      const getResponse = await getApi(`survey/?organisation=${orgId}`, filter);
      console.log(getResponse?.data?.data, "dropdown");
      setRespondent(getResponse?.data?.data?.total_invited_respondents);
      const modifyArray = getResponse?.data?.data.map((item, index) => {
        let statusStyle;

        switch (item.status) {
          case "completed":
            statusStyle = styles.active;
            break;
          case "ongoing":
            statusStyle = styles.paused;
            break;

          case "draft":
            statusStyle = styles.Inactive;
            break;
          default:
            statusStyle = styles.default;
        }

        return {
          ...item,
          status: <p className={statusStyle}>{item?.status}</p>,
          Sno: <p className={styles.head}>{index + 1}</p>,
          title: <p className={styles.head}>{item?.title}</p>,
          Createdby: (
            <p>
              {item?.created_by?.first_name} {item?.created_by?.first_name}
            </p>
          ),
        };
      });
      setTData(modifyArray);
    } catch (error) {
      console.error("Error fetching organisation data", error);
    }
  };
  console.log(tdata?.data, "dignoData");

  // survey/?organisation=3'
  // useEffect(() => {
  //   getDignostics();
  // }, []);
  console.log(form, "form");

  useEffect(() => {
    const applyFilters = () => {
      const finalFilters = {};
      // finalFilters.page = pageState;
      if (form?.start_date !== "" && form?.end_date !== "") {
        finalFilters.start_date__gt = form?.start_date;
        finalFilters.end_date__lt = form?.end_date;
      }

      getDignostics(finalFilters, orgId);
    };

    const timeout = setTimeout(() => {
      applyFilters();
    }, 600);

    return () => clearTimeout(timeout);
    // }
  }, [form]);
  useEffect(() => {
    getDignostics(orgId);
  }, []);
  console.log(form, "form");

  const getShareSurvey = async (id) => {
    try {
      const data = await getApi(`survey/${id}/qr_data/`);
      console.log(data, "shareDarta");
      setData(data?.data);
      getDignostics(orgId);
    } catch (error) {
      console.error(error);
    }
  };
  const patchShareSurvey = async (id) => {
    const dataInvited = { total_invited_respondents: invited };
    try {
      const data = await PatchApi(`survey/${id}/`, dataInvited);
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleLink = (id) => {
    getShareSurvey(id);
    setChange(id);

    console.log(id, "dars");
  };
  useEffect(() => {
    if (data.survey_url && change !== null) {
      window.open(data?.survey_url);
    }
  }, [data.survey_url]);
  console.log(change, "change");
  const handleChange = (name, data) => {
    setForm((prev) => ({
      ...prev,
      [name]:
        name === "start_date" || name === "end_date"
          ? stringToYYYYMMDD(data)
          : data,
    }));
  };
  const stringToYYYYMMDD = (dateString) => {
    const startDate = new Date(dateString);
    const formattedDate = startDate.toISOString().slice(0, 10);
    return formattedDate;
  };
  return (
    <div>
      <div>
        <div className={styles.dates1}>
          <h3 style={{ fontSize: "24px", fontWeight: "600" }}>
            Diagnostics Management
          </h3>
          <div className={styles.dates}>
            <div style={{ width: "200px" }}>
              <DateField
                placeHolder={"Start Date"}
                onChange={(date) => handleChange("start_date", date)}
                name="start_date"
                startDate={form.start_date}
              />
            </div>
            <div style={{ width: "200px" }}>
              <DateField
                placeHolder={"End Date"}
                onChange={(date) => handleChange("end_date", date)}
                name="end_date"
                startDate={form.end_date}
              />
            </div>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        rows={tdata}
        action={true}
        handleOpen={handleLink}
        open={open}
        openDialog={openDialog}
        handleDialog={handleDialog}
        handleCloseDialog={handleCloseDialog}
        handleView={handleView}
      />
      {openDialog && (
        <ShareSurveyPopUp
          openDialog={openDialog}
          handleDialog={handleDialog}
          handleCloseDialog={handleCloseDialog}
          data={data}
          respondatePrev={respondent}
          setInvited={setInvited}
          handlePatch={patchShareSurvey}
          surveyId={id}
        />
      )}
    </div>
  );
}

export default DiagnosticsManagement;
const columns = [
  { key: "Sno", heading: "S.no" },
  { key: "title", heading: "Survey Name" },
  { key: "CompanyName", heading: "Company Name" },
  { key: "Createdby", heading: "Created by" },
  { key: "status", heading: "Status" },
];

const rows = [
  {
    id: 1,
    Sno: "01",
    SurveyName: "Dorem ipsum dolor sit amet",
    CompanyName: "Starbucks",
    Createdby: "Olivia Rhye",
    Status: "Ongoing",
  },
  {
    id: 2,
    Sno: "02",
    SurveyName: "Survey Name",
    CompanyName: "Dorem ipsum dolor sit amet",
    Createdby: "Phoenix Baker",
    Status: "Draft",
  },
  {
    id: 3,
    Sno: "03",
    SurveyName: "Survey Name",
    CompanyName: "Dorem ipsum dolor sit amet",
    Createdby: "Lana Steiner",
    Status: "Completed",
  },
];
