import React, { useEffect } from "react";
import smsNotification from "../../assets/onboarding/smsNotification.svg";
import styles from "../Onboarding.module.css";
import ImageryText from "../ImageryText/ImageryText";
import ControlledButton from "../../Components/ControlledButton/ControlledButton";
import RegisterFields from "../RegisterFields/RegisterFields";
import BackIconComponent from "../BackIconComponent";
import CircularLoading from "../../Components/CircularLoading/CircularLoading";

function Step4({ fields, id, handleClick, formState, fieldsData, ...props }) {
  const { new_password, confirm_password } = fieldsData;

  useEffect(() => {
    if (new_password.value && new_password.value !== confirm_password.value) {
      confirm_password.setErrorExternally("password does not match");
    } else {
      confirm_password.setErrorExternally("");
    }
  }, [confirm_password, new_password]);

  return (
    <div className={styles.stepContainer}>
      <ImageryText image={smsNotification} primaryText="Forgot Password?">
        <span>
          Enter the email you used to create your account so we can send you a
          code for resetting your password.
        </span>
      </ImageryText>
      <div className={styles.formSection}>
        {fields.map((item, index) => {
          return (
            <div key={index}>
              <p style={{ textTransform: "capitalize" }}>{item.label}</p>
              <RegisterFields
                key={index}
                name={item.name}
                placeholder={item.placeholder}
                size="small"
                {...fieldsData[item.name]}
                style={{ width: "100%" }}
                type={item.type}
              />
            </div>
          );
        })}
        <p style={{ marginTop: "8px", color: "#ef5350" }}>{formState.error}</p>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px" }}
          onClick={() => {
            handleClick(id, "apicall");
          }}
          disabled={formState.loading}
        >
          {formState.loading && (
            <CircularLoading size={20} style={{ marginRight: "15px" }} />
          )}{" "}
          Set New Password
        </ControlledButton>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px", color: "black" }}
          variant="outlined"
          onClick={() => {
            handleClick(id, "redirection");
          }}
        >
          <BackIconComponent />
        </ControlledButton>
      </div>
    </div>
  );
}

export default Step4;
