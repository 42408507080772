import { dycryptAES } from "./crypt";
import Cookies from "js-cookie";
import { LOCAL_STRING, TOKEN } from "./strings";

const cookieValue = Cookies.get(LOCAL_STRING);
const sessionValue = sessionStorage.getItem(LOCAL_STRING);

function getData(keyname, value) {
  let decryptedData = null;
  try {
    decryptedData = dycryptAES(value);
  } catch {
    console.log("Error while fetching data from cookie");
  }
  return checkObject(keyname, decryptedData);
}

function checkObject(keyname, decryptedData) {
  if (decryptedData) {
    if (decryptedData[keyname]) {
      return decryptedData[keyname];
    }
  }
  if (keyname === TOKEN) return { access: "", refresh: "" };

  return "";
}

export const handleToken = () => {
  const token = getData(TOKEN, cookieValue || sessionValue);
  console.log(token,'handle token');
  return token;
};
