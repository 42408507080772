import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

export default function PositionedSnackbar({ formState = { loggedin: false }, ...props }) {
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = state;

    useEffect(() => {
        if (formState?.loggedin) {
            setState(prevState => ({ ...prevState, open: true }));
        }
    }, [formState])

    const handleClose = () => {
    };

    return (
        <Box sx={{ width: 500 }}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message="Logged in successfully"
                key={vertical + horizontal}
                autoHideDuration={6000}
                severity="success"
            />
        </Box>
    );
}
