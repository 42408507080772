import MiniDrawer from "../Components/DrawerComponent/DrawerComponent";
import Dashboard from "../Pages/Dashboard";
import UserManagement from "../Pages/RecommendedSolutions/RecommendedSolutions";
import OrgManagement from "../Pages/Scorecard/Scorecard";
import SolutionHubManagement from "../Pages/YourRespondents/YourRespondents";
import DataManagement from "../Pages/TurnoverInsights/TurnoverInsights";
import NOTFoundPage from "../Pages/NotFoundPage";
import Onboarding from "../Onboarding/Onboarding";
import { Navigate } from "react-router-dom";
import SurveyManagement from "../Pages/AttributionTracking/AttributionTracking";
import RoleBasedRouter from "../AppRouter/RoleBasedRouter/RoleBasedRouter";
import TurnoverInsights from "../Pages/TurnoverInsights/TurnoverInsights";
import Scorecard from "../Pages/Scorecard/Scorecard";
import YourRespondents from "../Pages/YourRespondents/YourRespondents";
import RecommendedSolutions from "../Pages/RecommendedSolutions/RecommendedSolutions";
import AttributionTracking from "../Pages/AttributionTracking/AttributionTracking";
import DiagnosticsManagement from "../Pages/DiagnosticsManagement/DiagnosticsManagement";
import Settings from "../Pages/Settings/Settings";
import Help from "../Pages/Help/Help";
import ScoreSearch from "../Components/ScoreSearch/ScoreSearch";
import SignUp from "../Onboarding/SignUp";

export const routerRoutes = [
  {
    path: "/login",
    element: (
      <RoleBasedRouter>
        <Onboarding />
      </RoleBasedRouter>
    ),
  },
  {
    path: "/sign-up",
    element: (
      <RoleBasedRouter>
        <SignUp />
      </RoleBasedRouter>
    ),
  },
  {
    path: "/",
    element: (
      <RoleBasedRouter>
        <MiniDrawer />
      </RoleBasedRouter>
    ),
    errorElement: <NOTFoundPage />,
    children: [
      { index: true, element: <Navigate to="/dashboard" replace /> },
      {
        path: "dashboard",
        element: (
          <RoleBasedRouter>
            <Dashboard />
          </RoleBasedRouter>
        ),
      },
      {
        path: "turnover-insights",
        element: (
          <RoleBasedRouter>
            <TurnoverInsights />
          </RoleBasedRouter>
        ),
      },
      {
        path: "turnover-insights/:id",
        element: (
          <RoleBasedRouter>
            <TurnoverInsights />
          </RoleBasedRouter>
        ),
      },
      {
        path: "scorecard",
        element: (
          <RoleBasedRouter>
            <Scorecard />
          </RoleBasedRouter>
        ),
      },
      {
        path: "scorecard/:id",
        element: (
          <RoleBasedRouter>
            <Scorecard />
          </RoleBasedRouter>
        ),
      },
      {
        path: "know-your-respondents",
        element: (
          <RoleBasedRouter>
            <YourRespondents />
          </RoleBasedRouter>
        ),
      },
      {
        path: "recommended-solutions",
        element: (
          <RoleBasedRouter>
            <RecommendedSolutions />
          </RoleBasedRouter>
        ),
      },
      {
        path: "attribution-tracking-tool",
        element: (
          <RoleBasedRouter>
            <AttributionTracking />
          </RoleBasedRouter>
        ),
      },
      {
        path: "diagnostics-management",
        element: (
          <RoleBasedRouter>
            <DiagnosticsManagement />
          </RoleBasedRouter>
        ),
      },
      {
        path: "settings",
        element: (
          <RoleBasedRouter>
            <Settings />
          </RoleBasedRouter>
        ),
      },
      {
        path: "help",
        element: (
          <RoleBasedRouter>
            <Help />
          </RoleBasedRouter>
        ),
      },
      {
        path: "score-search",
        element: (
          <RoleBasedRouter>
            <ScoreSearch />
          </RoleBasedRouter>
        ),
      },
    ],
  },
];
