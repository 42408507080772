import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getApi } from "../../Utility/network";
export default function Accordians() {
  const [expanded, setExpanded] = React.useState(null);
  const [faq, setFaq] = React.useState([]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const getData = async () => {
    try {
      const getResponse = await getApi(`profile/faqs/`);
      console.log(getResponse, "bfkjhkjhfkj");
      setFaq(getResponse.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ boxShadow: "0px 5px 15px 0px #27305014", padding: "20px" }}>
      <p style={{ fontSize: "20px", fontWeight: "500" }}>
        Frequently Asked Questions
      </p>
      {faq?.map((item, index) => {
        const panelId = `panel${index + 1}`;
        return (
          <Accordion
            expanded={expanded === panelId}
            onChange={handleChange(panelId)}
          >
            <AccordionSummary
              expandIcon={expanded === panelId ? <RemoveIcon /> : <AddIcon />}
              aria-controls={`${panelId}-content`}
              id={`${panelId}-header`}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: "14PX", fontWeight: "400" }}>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
const data = [
  {
    id: 1,
    heading:
      "Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris? ",
    desc: `Aenean vitae elit sed sem tempor tempus sed eu nulla. Cras molestie ultricies est ut venenatis. Fusce felis risus, fringilla at leo vitae, lobortis gravida sapien. Etiam mollis faucibus magna. Donec fringilla mattis augue pulvinar porta. Maecenas pretium urna leo, in vestibulum ipsum dictum quis. Pellentesque interdum ullamcorper ligula, nec venenatis odio laoreet vitae.
Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.`,
  },
  {
    id: 2,
    heading:
      "Elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris? ",
    desc: `Aenean vitae elit sed sem tempor tempus sed eu nulla. Cras molestie ultricies est ut venenatis. Fusce felis risus, fringilla at leo vitae, lobortis gravida sapien. Etiam mollis faucibus magna. Donec fringilla mattis augue pulvinar porta. Maecenas pretium urna leo, in vestibulum ipsum dictum quis. Pellentesque interdum ullamcorper ligula, nec venenatis odio laoreet vitae.
Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.`,
  },
  {
    id: 3,
    heading:
      " Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris?",
    desc: `Aenean vitae elit sed sem tempor tempus sed eu nulla. Cras molestie ultricies est ut venenatis. Fusce felis risus, fringilla at leo vitae, lobortis gravida sapien. Etiam mollis faucibus magna. Donec fringilla mattis augue pulvinar porta. Maecenas pretium urna leo, in vestibulum ipsum dictum quis. Pellentesque interdum ullamcorper ligula, nec venenatis odio laoreet vitae.
Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.`,
  },
  {
    id: 4,
    heading:
      " Iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris?",
    desc: `Aenean vitae elit sed sem tempor tempus sed eu nulla. Cras molestie ultricies est ut venenatis. Fusce felis risus, fringilla at leo vitae, lobortis gravida sapien. Etiam mollis faucibus magna. Donec fringilla mattis augue pulvinar porta. Maecenas pretium urna leo, in vestibulum ipsum dictum quis. Pellentesque interdum ullamcorper ligula, nec venenatis odio laoreet vitae.
Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.`,
  },
  {
    id: 5,
    heading:
      "Elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris? ",
    desc: `Aenean vitae elit sed sem tempor tempus sed eu nulla. Cras molestie ultricies est ut venenatis. Fusce felis risus, fringilla at leo vitae, lobortis gravida sapien. Etiam mollis faucibus magna. Donec fringilla mattis augue pulvinar porta. Maecenas pretium urna leo, in vestibulum ipsum dictum quis. Pellentesque interdum ullamcorper ligula, nec venenatis odio laoreet vitae.
Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.`,
  },
  {
    id: 6,
    heading:
      " Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris?",
    desc: `Aenean vitae elit sed sem tempor tempus sed eu nulla. Cras molestie ultricies est ut venenatis. Fusce felis risus, fringilla at leo vitae, lobortis gravida sapien. Etiam mollis faucibus magna. Donec fringilla mattis augue pulvinar porta. Maecenas pretium urna leo, in vestibulum ipsum dictum quis. Pellentesque interdum ullamcorper ligula, nec venenatis odio laoreet vitae.
Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.`,
  },
];
