import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import styles from "./DataTable.module.css";
import forword from "../../assets/componentAssest/forword.svg";
import share from "../../assets/componentAssest/share.svg";
import copy from "../../assets/componentAssest/copy.png";
import pdf from "../../assets/componentAssest/pdf.png";
import doc from "../../assets/componentAssest/doc.png";
import ShareSurveyPopUp from "../ShareSurveyPopUp/ShareSurveyPopUp";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F9FAFB",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const renderCellContent = (column, displayData, row, ...props) => {
  console.log(row, displayData, "dhjgdas");
  if (column.key === "status") {
    if (row[column.key] === "Ongoing") {
      return <p className={styles.Ongoing}>{displayData}</p>;
    } else if (row[column.key] === "draft") {
      return <p className={styles.Draft}>{displayData}</p>;
    } else if (row[column.key] === "Completed") {
      return <p className={styles.Completed}>{displayData}</p>;
    }
  } else if (column.key === "SurveyName") {
    return <p className={styles.fontColor}>{displayData}</p>;
  } else if (column.key === "Sno" || column.key === "name") {
    return <p className={styles.fontColor}>{displayData}</p>;
  } else if (column.key === "per") {
    return <p className={styles.fontColor}>{displayData}</p>;
  } else if (
    column.key === "insigth" ||
    column.key === "forth" ||
    column.key === "fifth"
  ) {
    return <p>{formatCellContent(displayData)}</p>;
  } else if (column.key === "CompanyName") {
    return <p className={styles.changeColor}>{row?.organisation?.title}</p>;
  } else if (column.key === "Createdby") {
    return <p className={styles.changeColor}>{displayData}</p>;
  }
  return displayData;
};
const formatCellContent = (text) => {
  if (!text) return "-";
  const [percentage, ...rest] = text.split(" ");
  const restText = rest.join(" ");
  return (
    <div style={{ textAlign: "start" }}>
      <span style={{ color: "#101828", fontWeight: "400", fontSize: "14px" }}>
        {percentage}
      </span>
      <br />
      <span style={{ color: "#667085", fontSize: "12px" }}>{restText}</span>
    </div>
  );
};

export default function DataTable({
  rows,
  columns,
  action,
  formatCellContent,
  // handleOpen,
  open,
  openDialog,
  handleDialog = () => {},
  handleCloseDialog,
  handleView = () => {},
  handleOpen=()=>{},
  ...props
}) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column.key} className={styles.headingstyle}>
                  {column.heading}
                </TableCell>
              ))}
              {action && (
                <TableCell className={styles.headingstyle}>
                  Quick Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {columns?.map((column) => {
                  const displayData = !column.key
                    ? row[column.key]
                    : row[column.key];
                  return (
                    <TableCell key={column.key} className={styles.dataStyle}>
                      <div style={{ fontSize: "14px" }}>
                        {renderCellContent(column, displayData, row)}
                      </div>
                    </TableCell>
                  );
                })}
                {action && (
                  <TableCell align="right" className={styles.ActionsStyle}>
                    <img
                      src={forword}
                      alt="Forward"
                      style={{ padding: "10px", cursor: "pointer" }}
                      onClick={() => handleOpen(row.id)}
                    />
                    <img
                      src={share}
                      alt="Share"
                      style={{ padding: "10px", cursor: "pointer" }}
                      onClick={() => handleDialog(row)}
                    />
                    {/* {open == row.id && (
                      <div className={styles.actionCon}>
                        <div className={styles.types}>
                          <div>
                            <img src={copy} />
                          </div>
                          <p className={styles.actName}>Copy</p>
                        </div>
                        <div className={styles.types}>
                          <div>
                            {" "}
                            <img src={pdf} />
                          </div>
                          <p className={styles.actName}>Download as PDF</p>
                        </div>
                        <div className={styles.types}>
                          <div>
                            <img src={doc} />
                          </div>
                          <p className={styles.actName}>Download as Doc</p>
                        </div>
                      </div>
                    )} */}

                 
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
