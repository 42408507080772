import React from "react";
import styles from "./RecommendedCards.module.css";
import money from "../../assets/componentAssest/money.png";
import arrow from "../../assets/componentAssest/arrow.png";
import { truncateText } from "../../Utility/helper";

function RecommendedCards({ data }) {
  console.log(data, "data");

  return (
    <div className={styles.Con}>
      <div className={styles.img} style={{ width: "100%", height:"50%" }}>
        {data.thumbnail ? (
          <img src={data.thumbnail} style={{ width: "100%",  height:"100%"}}></img>
        ) : (
          <img src={money} style={{ width: "100%",  height:"100%"}}></img>
        )}
      </div>
      <h3 className={styles.heading}>{data.title}</h3>
      <p className={styles.desc}>
        {data?.description ? truncateText(data?.description, 150) : ""}
      </p>
      {data?.score_type?.title && (
        <span className={styles.tag}>{data?.score_type?.title}</span>
      )}
      <button className={styles.btn}>
        {" "}
        Preview
        <div>
          {" "}
          <img src={arrow}></img>
        </div>
      </button>
    </div>
  );
}

export default RecommendedCards;
