import React, { useContext } from "react";
import { AppContext } from "../../../ContextApi/AppContext/AppContext";
import { Button } from "@mui/material";
import styles from "./TopCard.module.css";
import { useNavigate } from "react-router-dom";

function TopCard(props) {
  const { contextState } = useContext(AppContext);
  const navigate = useNavigate();
  const 
  handleNavigate = (url) => {
    navigate(url);
  };
  return (
    <div className={styles.cci} style={{ padding: "10px" }}>
      <div>
        <p style={{ fontSize: "25px", margin: "10px 0px 5px" }}>
          Welcome back,{" "}
          <b style={{ textTransform: "capitalize" }}>
            {contextState?.user?.first_name}
          </b>
           👋
        </p>
        <p style={{ margin: "0px", fontSize: "14px" }}>
          <b></b>Dig deeper so you can see which score might reduce your
          turnover intention
        </p>
      </div>
      <div className={styles.buttonC}>
        <Button
          variant="contained"
          style={{ marginRight: "10px", fontWeight: "900" }}
          onClick={() => handleNavigate("/scorecard")}
        >
          See Scorecard
        </Button>
        <Button
          variant="contained"
          style={{ fontWeight: "900" }}
          onClick={() => handleNavigate("/turnover-insights")}
        >
          Turnover Insights
        </Button>
      </div>
    </div>
  );
}

export default TopCard;
