import React, { useState } from "react";
import styles from "./ChangePassword.module.css";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { PostApi } from "../../Utility/network";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function ChangePassword() {
  const [formValue, setFormValue] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };
  const clearState = () => {
    setFormValue({
      current_password: "",
      new_password: "",
      confirm_password: "",
    });
  };

  const postData = async () => {
    const password = {
      current_password: formValue?.current_password,
      new_password: formValue?.new_password,
      confirm_password: formValue?.confirm_password,
    };
    try {
      const getResponse = await PostApi(`auth/change_password/`, password);
      console.log(getResponse, "bfkjhkjhfkj");
    } catch (error) {}
  };

  return (
    <div className={styles.container}>
      <div>
        <div>
          <p className={styles.name}>Current Password</p>
          <TextField
            type={showCurrentPassword ? "password" : "text"}
            placeholder="Current Password"
            size="small"
            sx={{ width: "30%" }}
            name="current_password"
            onChange={handleChangeINput}
            value={formValue?.current_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                    {showCurrentPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <p className={styles.name}>New Password</p>
          <TextField
            type={showNewPassword ? "password" : "text"}
            size="small"
            placeholder="New Password"
            sx={{ width: "30%" }}
            name="new_password"
            onChange={handleChangeINput}
            value={formValue?.new_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <p className={styles.name}>Confirm Password</p>
          <TextField
            type={showConfirmPassword ? "password" : "text"}
            size="small"
            placeholder="Confirm New Password"
            sx={{ width: "30%" }}
            name="confirm_password"
            onChange={handleChangeINput}
            value={formValue?.confirm_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className={styles.btns}>
        <Button
          variant="contained"
          onClick={() => {
            postData();
            clearState();
          }}
        >
          Change Password
        </Button>
        <Button variant="outlined">close</Button>
      </div>
    </div>
  );
}

export default ChangePassword;
