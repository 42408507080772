import axios from "axios";
import { dycryptAES, encryptAES } from "./crypt";
import { LOCAL_STRING } from "./strings";
import Cookies from "js-cookie";
import { handleToken } from "./CheckToken";
const getApiBaseUrl = () => {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return "https://dev-api.samalives.com";
  } else if (hostname.includes("samalives")) {
    return "https://prod-api.samalives.com";
  } else {
    return "https://dev-api.samalives.com"; // fallback URL
  }
};
// Base URL Configuration
// const baseURL =
//   process.env.REACT_APP_API_URL || "http://prod-api.samalives.com"; // Adjust to your API

export const baseURL = getApiBaseUrl();
// http://prod-api.samalives.com
// http://13.200.202.38
// Create an Axios Instance with Default Configuration
export const api = axios.create({
  baseURL,
  timeout: 100000000,
  headers: {
    // 'Content-Type': 'application/json', // Common header
    "Content-Type": "application/octet-stream",

    // Add other default headers if needed
  },
});

// Request Interceptor (Optional)
// api.interceptors.request.use(
//   (config) => {
//     // Modify the request config (e.g. add authorization headers)
//     let token = handleToken();
//     if (token && token.access) {
//       config.headers.Authorization = `Bearer ${token.access}`;
//     }
//     if (config.data) {
//       console.log(typeof config.data, config.data, "encrypt1");
//       config.data = encryptAES(config.data);
//       console.log(typeof config.data, config.data, "encrypt1");

//       // various ways to transform data

//       // config.data.newProperty = 'newValue';

//       // config.data = {
//       //     ...config.data,
//       //     transformedProperty: config.data.originalProperty * 2,
//       // };
//     }
//     return config;
//   },
//   (error) => {
//     // Handle request errors
//     return Promise.reject(error);
//   }
// );

// Response Interceptor (Optional - For Global Error Handling)
api.interceptors.response.use(
  (response) => {
    // Modify the response data if needed
    if (response.status === 204) return { data: {} };
    const dycryptedData = dycryptAES(response.data);
    return dycryptedData; // By default, return data directly
  },
  (error) => {
    // Handle response errors globally
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.error('Response error:', error.response.data);
      // Handle specific error codes (e.g., 401 Unauthorized, 500 Internal Server Error)
      if (error.response && error.response.status === 401) {
        const originalRequest = error.config; // Get original request config
        const url = originalRequest.url;
        // logoutUser
        if (url !== "/auth/login/") {
          sessionStorage.clear();
          Cookies.remove(LOCAL_STRING);
          window.location.href = "/";
        }
      }
      const encryptedData = error.response.data;
      try {
        const decryptedData = dycryptAES(encryptedData);
        // Handle the decrypted data as needed
        // console.log("Decrypted data:", decryptedData);
        // You might want to re-throw the error or take some other action
        // depending on what the decrypted data contains or indicates

        return Promise.reject(decryptedData); // Propagate the error to your components
      } catch (decryptError) {
        console.error("Error decrypting data:", decryptError);
        return Promise.reject(error); // Propagate the error to your components
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Request error:", error.request);
      return Promise.reject(error); // Propagate the error to your components
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message);
      return Promise.reject(error); // Propagate the error to your components
    }
  }
);

// Reusable API Function

export const getApi = async (endpoint, filters) => {
  try {
    const response = await api.get(`${endpoint}`, { params: { ...filters } });
    console.log(response, endpoint, "response from network");
    return response;
  } catch (error) {
    throw error;
  }
};

export const PostApi = async (endpoint, postData) => {
  try {
    const response = await api.post(`${endpoint}`, postData);
    console.log(response, endpoint, "response from network");
    return response;
  } catch (error) {
    console.log(error, "response11");
    return error;
  }
};

export const PatchApi = async (endpoint, postData) => {
  try {
    const response = await api.patch(`${endpoint}`, postData);
    return response;
  } catch (error) {
    console.log(error, postData, "response11");
    return error;
  }
};

// Add more API functions (updateItem, deleteItem, etc.) as needed

export default api;
