import React, { useContext } from "react";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { Navigate, useLocation } from "react-router-dom";

function RoleBasedRouter({ children }) {
  const { token } = useContext(AppContext);
  const location = useLocation();

  // Check if the user is not logged in (no access token)
  if (!token.access) {
    // If the user tries to access any page other than login or sign-up, redirect to login
    if (location.pathname !== "/login" && location.pathname !== "/sign-up") {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  } else {
    // If the user is logged in and tries to access the login page, redirect to dashboard
    if (location.pathname === "/login") {
      return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }
  }

  return children; // Allow the user to access the page if they meet the conditions
}

export default RoleBasedRouter;
