import { Dialog, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import VerticalChart from "../VerticalChart/VerticalChart";

function InnerChart({ handleClose = () => {}, open, Chartdata, quitText }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (Chartdata) {
      setLoading(false);
    }
  }, [Chartdata]);
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
      {loading ? (
        <Skeleton variant="rounded" width={"100%"} height={350} />
      ) : (
        <VerticalChart
          data={Chartdata}
          text={quitText}
          width={130}
          Label={true}
        />
      )}
    </Dialog>
  );
}

export default InnerChart;
