import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import profileImg from "../../assets/componentAssest/profileImg.png";
import EditProfile from "../EditProfile/EditProfile";
import { getApi } from "../../Utility/network";
function Profile() {
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState();
  const [dropDown, setDropDown] = useState();
  const [drop, setDrop] = useState({
    designation: "",
  });
  // const [edit, setEdit] = useState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOPen = () => {
    setOpen(true);
  };
  const getProfileData = async () => {
    try {
      const getResponse = await getApi(`auth/user/me`);
      console.log(getResponse, "bfkjhkjhfkj");
      setProfile(getResponse.data);
    } catch (error) {}
  };
  const id = profile?.id;
  const useProfileId = profile?.profile?.id;
  const getOrgData = async () => {
    try {
      const getResponse = await getApi(`profile/designation`);
      console.log(getResponse, "dropdown");
      setDropDown(getResponse.data);
    } catch (error) {}
  };

  useEffect(() => {
    getOrgData();
  }, []);
  useEffect(() => {
    getProfileData();
  }, []);
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle if dateString is undefined or null
    const date = new Date(dateString); // Convert string to Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Return formatted date
  };
  return (
    <>
      {" "}
      <div className={styles.con}>
        {/* <h3 className={styles.head}>Settings</h3>
            <div className={styles.headerCon}>
                {headers.map((item) => {
                    return (
                        <div>
                            <p className={styles.tabs}>{item.name}</p>
                        </div>
                    )
                })}
            </div> */}

        <div className={styles.infoContainer}>
          <div>
            <div className={styles.Profile}>
              <div className={styles.img}>
                <img src={profile?.profile?.avatar}></img>
              </div>
              <p className={styles.name}>
                {profile?.first_name} {profile?.last_name}
              </p>
            </div>

            <div className={styles.basicInfoCon}>
              <div className={styles.basicInfo}>
                <p className={styles.title}>Email</p>
                <p className={styles.value}>{profile?.email}</p>
              </div>
              <div className={styles.basicInfo}>
                <p className={styles.title}>Mobile Number</p>
                <p className={styles.value}>{profile?.phone}</p>
              </div>
              <div className={styles.basicInfo}>
                <p className={styles.title}>Company Name</p>
                <p className={styles.value}>
                  {profile?.profile?.organisation?.title}
                </p>
              </div>
              <div className={styles.basicInfo}>
                <p className={styles.title}>Designation</p>
                <p className={styles.value}>
                  {profile?.profile?.designation?.title}
                </p>
              </div>
              <div className={styles.basicInfo}>
                <p className={styles.title}>Joining Date</p>
                <p className={styles.value}>
                  {formatDate(profile?.date_joined)}
                </p>
              </div>
            </div>
            <div className={styles.basicInfo}>
              <p className={styles.title}>Bio</p>
              <p className={styles.bio}>{profile?.profile?.bio}</p>
            </div>
          </div>
          <div className={styles.btn}>
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOPen();
                // getEditData();
              }}
            >
              Edit Profile{" "}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.pop}>
        {open && (
          <EditProfile
            open={open}
            handleClose={handleClose}
            id={id}
            profile={profile}
            drop={drop}
            setDrop={setDrop}
            dropDown={dropDown}
            getProfileDataprev={getProfileData}
            useProfileId={useProfileId}
          />
        )}
      </div>
    </>
  );
}

export default Profile;
// const headers = [
//     {
//         id: 1,
//         name: "Profile"
//     },
//     {
//         id: 2,
//         name: "Notification Settings"
//     },
//     {
//         id: 3,
//         name: "Change Password"
//     },
//     {
//         id: 4,
//         name: "Term & Conditions "
//     },
// ]
const info = {
  email: "rhona.asg@gmail.com",
  mNo: "(208) 555-0112",
  CName: "The Walt Disney Company",
  designation: "Human Resource Manager",
  joiningDate: "20/06/2021",
  bio: ' Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
};
