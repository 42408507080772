import React from "react";
import VerticalChart from "../VerticalChart/VerticalChart";

function MedianTenure({ ChartData }) {
  const text = "Median Tenure";
  return (
    <div>
      <VerticalChart data={ChartData} text={text} width={120}Label={true} />
    </div>
  );
}

export default MedianTenure;
