export const capitalise = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const emailValidation = (email) => {
  const validationError = /\S+@\S+\.\S+/.test(email) ? "" : "Invalid email id";
  return { error: validationError, status: false };
};

export const stringToYYYYMMDD = (dateString) => {
  const startDate = new Date(dateString);
  const formattedDate = startDate.toISOString().slice(0, 10);
  return formattedDate;
};

// const areAllFieldsEntered = (fields) => {
//     return Object.values(fields).every(field => field.value.trim() !== '');
// };

// // Utility function to check if any field has an error
// const hasAnyError = (fields) => {
//     return Object.values(fields).some(field => field.error !== '');
// };
export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}