import React, { useContext, useEffect, useState } from "react";
import styles from "./SplitWise.module.css";
import PieDougnutChart from "../../../../Components/PieDoughnutChart/PieDoughnutChart";
import { getApi } from "../../../../Utility/network";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../ContextApi/AppContext/AppContext";
import { deepPurple } from "@mui/material/colors";
import { MenuItem, Select } from "@mui/material";

function SplitWise({ time }) {
  const { contextState } = useContext(AppContext);
  const { selectedSurvey } = contextState;
  const [department, setDepartment] = useState([]);
  // const [dropDown, setDropDown] = useState({
  //   month: [
  //     { id: 1, name: "in 12 Months" },
  //     { id: 2, name: "in 11 Months" },
  //   ],
  // });

  const [values, setValues] = useState({
    monthsValue: "",
  });
  const spit = [
    { id: 1, name: "Sales", value: department?.Sales },

    { id: 2, name: "Marketing", value: department?.Marketing },

    { id: 3, name: "Operations", value: department?.Operations },

    {
      id: 4,
      name: "Business Development",
      value: department?.["Business Development"],
    },

    { id: 5, name: "Finance", value: department?.Finance },

    {
      id: 6,
      name: "Information Technology",
      value: department?.["Information Technology"],
    },

    { id: 7, name: "Human Resources", value: department?.["Human Resources"] },

    { id: 8, name: "Engineering", value: department?.Engineering },

    { id: 9, name: "Legal", value: department?.Legal },

    {
      id: 10,
      name: "Product Management",
      value: department?.["Product Management"],
    },

    {
      id: 11,
      name: "Quality Assurance",
      value: department?.["Quality Assurance"],
    },

    { id: 12, name: "Research", value: department?.Research },

    { id: 13, name: "Strategy", value: department?.Strategy },

    { id: 14, name: "Support", value: department?.Support },

    {
      id: 15,
      name: "Community & Social Services",
      value: department?.["Community & Social Services"],
    },

    { id: 16, name: "Art & Design", value: department?.["Art & Design"] },

    { id: 17, name: "Administrative", value: department?.Administrative },

    { id: 18, name: "Accounting", value: department?.Accounting },

    {
      id: 19,
      name: "Media & Communication",
      value: department?.["Media & Communication"],
    },

    {
      id: 20,
      name: "Program and Project Management Purchasing",
      value: department?.["Program and Project Mangement Purchasing"],
    },

    { id: 21, name: "Others", value: department?.Others },
  ];

  const [active, setActive] = useState({
    id: "",
    innerData: [],
  });

  const handleClick = (id, value) => {
    setActive({
      id: id,
      innerData: value,
    });
  };
  console.log(values?.monthsValue, "month");

  useEffect(() => {
    if (selectedSurvey.id !== null) {
      getDepartment(selectedSurvey.id);
    }
  }, [selectedSurvey, values]);
  const getDepartment = async (id) => {
    if (!id) return;
    const filter = {};

    if (values?.monthsValue && values.monthsValue !== "all") {
      filter.time_frame = values.monthsValue;
    }
    try {
      const getResponse = await getApi(
        `/survey/dashboard/department_split/${id}`,
        filter
      );
      setDepartment(getResponse.data);
      console.log(getResponse, "department");

      const firstDepartment = {
        id: spit[0]?.id,
        innerData: getResponse.data?.Sales,
      };
      setActive(firstDepartment);
    } catch (error) {}
  };

  const arr = [
    {
      title: `Entry Level (${active?.innerData?.Entry?.percentage}%)`,
      percentage: `${active?.innerData?.Entry?.count}`,
      // level: "Entry",
    },
    {
      title: `Mid-Senior Level  (${active?.innerData?.["Mid-Senior"]?.percentage}%)`,
      percentage: `${active?.innerData?.["Mid-Senior"]?.count}`,
      // level: "Entry",
    },
    {
      title: `Senior Level  (${active?.innerData?.Senior?.percentage}%)`,
      percentage: `${active?.innerData?.Senior?.count}`,
      // level: "Entry",
    },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  return (
    <div
      style={{
        boxShadow: `0px 5px 15px 0px #27305014`,
        padding: "10px",
        marginTop: "20px",
      }}
    >
      <div className={styles.filters}>
        <h2 className={styles.head}>Department Wise Split</h2>
        <div className={styles.fieldCon}>
          <Select
            value={values.monthsValue || "all"} // Ensure a fallback value
            onChange={handleChange}
            sx={{ width: "100%" }}
            size="small"
            name="monthsValue"
          >
            {time?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{ width: "40%", maxHeight: "300px", overflow: "auto" }}
          className={styles.menu}
        >
          {spit?.map((item) => {
            return (
              <ul key={item.id}>
                <li
                  onClick={() => handleClick(item?.id, item.value)}
                  className={active.id === item.id ? styles.active : ""}
                >
                  {item.name}
                </li>
              </ul>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "60%",
          }}
        >
          <div>
            {arr.map((item, index) => {
              return (
                <div className={styles.dots} key={index}>
                  <p style={{ marginBottom: "8px" }}>
                    <b>{item.title}</b>
                  </p>
                  <p style={{ marginTop: "8px" }}>
                    {item.percentage} {item.level} level employees
                  </p>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "-45px" }}>
            <PieDougnutChart chartData={active?.innerData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SplitWise;
