import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Drawer, DrawerHeader, drawerList, support } from "./utility";
import drawerIcon from "../../assets/componentAssest/drawerIcon.svg";
import styles from "./DrawerComponent.module.css";
import { PRIMARYCOLOR } from "../../Utility/constants";
import SearcHTextField from "../SearchTextField/SearcHTextField";
import notification from "../../assets/notification.svg";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logoutImg from "../../assets/componentAssest/logoutImg.svg";

export default function MiniDrawer() {
  const { token, handleLogout, contextState, setSearchValue, searchValue } =
    useContext(AppContext);
  let location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(1);
  // useEffect(() => {
  //   console.log(token)
  //   if (token.access === "") handleLogout();
  // }, [token]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    const currentObject = drawerList.find((item, index) => {
      let str = location.pathname.replace(/^\/+/, "");
      return item.path === str;
    });
    if (currentObject) {
      setActive(currentObject.id);
    }
  }, [location]);
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setSearchValue(value);
  };
  console.log(search, "search");
  console.log(searchValue, "searchValue");
  useEffect(() => {
    if (searchValue.length > 0) {
      navigate("/score-search");
    }
  }, [searchValue]);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          <div className={styles.headerContainer}>
            <div style={{ width: "30%" }}>
              <SearcHTextField
                placeholder="Search for Scores..."
                sx={{ width: "100%" }}
                handleSearch={handleSearch}
                search={search}
              />
            </div>
            <div className={styles.he1}>
              <div className={styles.noti}>
                <img src={notification} alt="" />
              </div>
              <div
                className={styles.rightName}
                onClick={() => navigate("/settings")}
              >
                <AccountCircleIcon fontSize="large" />
                <div className={styles.nameLis}>
                  <p className={styles.name}>
                    {contextState?.user?.first_name}
                  </p>
                  <p className={styles.email}>{contextState?.user?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </Typography>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <div style={{ color: "white" }}>
          <DrawerHeader>
            <img alt="sama logo" src={drawerIcon} style={{ width: "100%" }} />
          </DrawerHeader>
          <div className={styles.listingCon}>
            <div>
              {drawerList.slice(0, 7).map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    className={({ isActive }) =>
                      [
                        styles.listingInd,
                        isActive ? styles.activeDiv : styles.inActiveDiv,
                      ].join(" ")
                    }
                    style={({ isActive }) => {
                      return {
                        color: `${isActive ? PRIMARYCOLOR : "white"}`,
                        borderLeft: `${
                          isActive
                            ? `3px solid ${PRIMARYCOLOR}`
                            : "3px solid black"
                        }`,
                      };
                    }}
                    to={item.path}
                  >
                    <div className={styles.imageCon}>
                      {active === item.id ? (
                        <img
                          alt=""
                          src={item.active_icon}
                          style={{ width: "30px" }}
                        />
                      ) : (
                        <img
                          alt=""
                          src={item.inactive_icon}
                          style={{ width: "30px" }}
                        />
                      )}
                    </div>
                    <div>
                      <span>{item.title}</span>
                    </div>
                  </NavLink>
                );
              })}
            </div>
            <div className={styles.line}></div>
            <div className={styles.bottomContent}>
              <p className={styles.support}>SUPPORT</p>

              <div>
                {drawerList.slice(7).map((item, index) => {
                  return (
                    <NavLink
                      key={index}
                      className={({ isActive }) =>
                        [
                          styles.listingInd,
                          isActive ? styles.activeDiv : styles.inActiveDiv,
                        ].join(" ")
                      }
                      style={({ isActive }) => {
                        return {
                          color: `${isActive ? PRIMARYCOLOR : "white"}`,
                          borderLeft: `${
                            isActive
                              ? `3px solid ${PRIMARYCOLOR}`
                              : "3px solid black"
                          }`,
                        };
                      }}
                      to={item.path}
                    >
                      <div className={styles.imageCon}>
                        {active === item.id ? (
                          <img
                            alt=""
                            src={item.active_icon}
                            style={{ width: "30px" }}
                          />
                        ) : (
                          <img
                            alt=""
                            src={item.inactive_icon}
                            style={{ width: "30px" }}
                          />
                        )}
                      </div>
                      <div>
                        <span>{item.title}</span>
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={styles.logout} onClick={handleLogout}>
            <img src={logoutImg} style={{ marginRight: "10px" }} />
            <p style={{ color: "#EE6C77" }}>Log out</p>
          </div>
        </div>
      </Drawer>
      <div className={styles.outletContainer} style={{ width: "100%" }}>
        <Outlet />
      </div>
    </Box>
  );
}
