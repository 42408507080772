import { Button, Dialog } from "@mui/material";
import React from "react";

function ACK({ open, handleClose }) {
  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems:"center"
          }}
        >
          <h3> Your details submmited successfully</h3>
          <div style={{ width: "50px", textAlign: "center" }}>
            <Button variant="contained" onClick={handleClose}>
              Ok
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ACK;
