import React from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

function SearcHTextField({
  variant = "outlined",
  size = "small",
  type = "text",
  placeholder = "Search Fields",
  label = "",
  name = "",
  title = "",
  style = { width: "100%" },
  handleSearch = () => {},
  search,
  ...props
}) {
  return (
    <div>
      {title && <p>{title}</p>}
      <TextField
        variant={variant}
        type={type}
        placeholder={placeholder}
        size={size}
        style={style}
        name="search"
        value={search}
        onChange={(e) => handleSearch(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default SearcHTextField;
