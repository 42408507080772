import React from "react";
import Accordians from "../../Components/Accordians/Accordians";
import ContactUs from "../../Components/ContactUs/ContactUs";

function Help() {
  return (
    <div>
      <p style={{fontSize:"24px",fontWeight:"600",margin:"0px"}}>Help</p>
      <Accordians />
      <ContactUs/>
    </div>
  );
}

export default Help;
