import React from "react";
import styles from "./InitativepOpUp.module.css";
import {
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
function InitativepOpUp({open,handleClose}) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const data = [
    { id: 1, name: "A" },
    { id: 2, name: "B" },
    { id: 3, name: "C" },
  ];

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}  maxWidth="md" >

    <div className={styles.con}>
      <div className={styles.top}>
        <h3 className={styles.heading} > Add Initiative</h3>
        <CloseIcon onClick={handleClose} style={{cursor:"pointer"}}  />
      </div>
      <div className={styles.fieldCon}>
        <p className={styles.name}>Program Name</p>

        <TextField
          placeholder="Program Name"
          size="small"
          sx={{ width: "100%" }}
        />
      </div>
      <div className={styles.fieldCon}>
        <p className={styles.name}>Program LeadProgram Lead (Company SPOC)</p>
        <TextField
          placeholder="Program Lead"
          size="small"
          sx={{ width: "100%" }}
        />
      </div>

      <div className={styles.divide}>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Program Cost</p>
          <TextField
            placeholder="Program Cost"
            size="small"
            sx={{ width: "100%" }}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end"  >
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
  
          />
        </div>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Status</p>
          <Select
            value={age}
            onChange={handleChange}
            sx={{ width: "100%" }}
            size="small"
            IconComponent={KeyboardArrowDownIcon}
          >
            {data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.divide}>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Format</p>
          <Select
            value={age}
            onChange={handleChange}
            sx={{ width: "100%" }}
            size="small"
            IconComponent={KeyboardArrowDownIcon}
          >
            {data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Duration</p>
          <Select
            value={age}
            onChange={handleChange}
            sx={{ width: "100%" }}
            size="small"
            IconComponent={KeyboardArrowDownIcon}
          >
            {data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.divide}>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Start Date</p>
          <TextField
            placeholder="Select Date"
            size="small"
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={styles.fieldCon}>
          <p className={styles.name}>End Date</p>
          <TextField
            placeholder="Select Date"
            size="small"
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className={styles.btns}>
        <Button variant="contained">Add Initiative</Button>
        <Button variant="outlined" onClick={handleClose}>Close</Button>
      </div>
    </div>
    </Dialog>
  );
}

export default InitativepOpUp;
