export const LOCAL_STRING = "sama_admin_panel";
export const TOKEN = "token";
export const USER = "user";
export const REMEMBER = "remember";
export const SURVEY = "survey";
export const ALLSECTION = "allSection";
export const CURRENTSECTION = "currentSection";
export const SURVEYPAGE = "surveyPage";
export const SELECTEDSURVEY = "selectedSurvey";
export const ORGID = "orgId";
