import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function NOTFoundPage(props) {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1 style={{ fontSize: "35px" }}>Page not found</h1>
      <Link to="/">
        <Button variant="contained">Back to panel</Button>
      </Link>
    </div>
  );
}

export default NOTFoundPage;
