import React from "react";
import styles from "./EmptyPage.module.css";
import { Button } from "@mui/material";
function EmptyPage({
  image,
  headerText,
  descText,
  pageHeading,
  buttonShow,
  handleOpen,
}) {
  return (
    <div className={styles.con}>
      <h3 className={styles.head}>{pageHeading}</h3>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <div className={styles.img}>
            <img src={image}></img>
          </div>
          <p className={styles.heading}> {headerText} </p>
          <p className={styles.desc}>{descText}</p>
          {buttonShow == true && (
            <div>
              <Button variant="contained" onClick={handleOpen} disabled={true}>
                ADD NEW INITATIVE{" "}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmptyPage;
