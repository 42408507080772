import React, { useEffect, useState } from "react";
import ScoreChartCom from "../../Components/ScoreChartCom/ScoreChartCom";
import AmbitionScore from "../../Components/AmbitionScore/AmbitionScore";
import DataTable from "../../Components/DataTable/DataTable";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import scoreCard from "../../assets/componentAssest/scoreCard.png";
import { getApi } from "../../Utility/network";
function Scorecard() {
  //   const score=data.ambition_score
  // console.log(score,"hfuhsf");

  return (
    <div>
      <ScoreChartCom />
    </div>
  );
}

export default Scorecard;
