import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: "Page G",
    uv: 5490,
    pv: 5300,
    amt: 3100,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 1800,
    amt: 1181,
  },
];

export default function AreaChartFill({ type, index, ...props }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer>
        <AreaChart
          data={index === 0 ? data1 : index === 1 ? data2 : data3}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <Tooltip /> */}
          <Area
            type="monotone"
            dataKey="uv"
            stroke={`${type ? `#12B76A` : `#DE4F31`}`}
            fill={`${type ? `#12B76Aaa` : `#DE4F31aa`}`}
            strokeWidth={3} // Set stroke width here
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

const data1 = [
  {
    name: "Page A",
    uv: 0,
    pv: 0,
    amt: 0,
  },

  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 1800,
    amt: 1181,
  },
  {
    name: "Page G",
    uv: 5490,
    pv: 5300,
    amt: 3100,
  },
];

const data2 = [
  {
    name: "Page A",
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 1800,
    amt: 1181,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page G",
    uv: 5490,
    pv: 5300,
    amt: 3100,
  },
];

const data3 = [
  {
    name: "Page A",
    uv: 0,
    pv: 0,
    amt: 0,
  },
  {
    name: "Page G",
    uv: 5490,
    pv: 5300,
    amt: 3100,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 1800,
    amt: 1181,
  },
];
