import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material";
import dbinactive from "../../assets/dashboard/dbinactive.svg";
import dbactive from "../../assets/dashboard/dbactive.svg";
import turnoverac from "../../assets/dashboard/turnoverac.svg";
import turnoverin from "../../assets/dashboard/turnoverin.svg";
import respondedac from "../../assets/dashboard/respondedac.svg";
import respondedin from "../../assets/dashboard/respondedin.svg";
import scoreboardac from "../../assets/dashboard/scoreboardac.svg";
import scoreboardin from "../../assets/dashboard/scoreboardin.svg";
import reccomendedac from "../../assets/dashboard/reccomendedac.svg";
import reccomendedin from "../../assets/dashboard/reccomendedin.svg";
import attributinac from "../../assets/dashboard/attributinac.svg";
import attributinin from "../../assets/dashboard/attributinin.svg";
import diagnosticsac from "../../assets/dashboard/diagnosticsac.svg";
import diagnosticsin from "../../assets/dashboard/diagnosticsin.svg";
// import datmanin from "../../assets/dashboard/datmanin.svg";
import settingact from "../../assets/dashboard/settingact.svg";
import helpact from "../../assets/dashboard/helpact.svg";
import settingin from "../../assets/dashboard/settingin.svg";
import helpin from "../../assets/dashboard/helpin.svg";

const drawerWidth = 280;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: "black",
  color: "white",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  background: "black",
  color: "white",
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "white",
  ...(open
    ? {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
    : {
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const drawerList = [
  {
    title: "Dashboard",
    id: 1,
    active_icon: dbactive,
    inactive_icon: dbinactive,
    path: "dashboard",
  },
  {
    title: "Turnover Insights",
    id: 2,
    active_icon: turnoverac,
    inactive_icon: turnoverin,
    path: "turnover-insights",
  },
  {
    title: "Scorecard",
    id: 3,
    active_icon: scoreboardac,
    inactive_icon: scoreboardin,
    path: "scorecard",
  },
  {
    title: "Know Your Respondents",
    id: 4,
    active_icon: respondedac,
    inactive_icon: respondedin,
    path: "know-your-respondents",
  },

  {
    title: "Recommended Solutions",
    id: 5,
    active_icon: reccomendedac,
    inactive_icon: reccomendedin,
    path: "recommended-solutions",
  },
  {
    title: "Attribution Tracking Tool",
    id: 6,
    active_icon: attributinac,
    inactive_icon: attributinin,
    path: "attribution-tracking-tool",
  },
  {
    title: "Diagnostics Management",
    id: 7,
    active_icon: diagnosticsac,
    inactive_icon: diagnosticsin,
    path: "diagnostics-management",
  },
  {
    title: "Settings ",
    id:10  ,
    active_icon:  settingact,
    inactive_icon: settingin ,
    path: "settings"
  },
  {
    title: "Help ",
    id:11 ,
    active_icon:helpact  ,
    inactive_icon:helpin  ,
    path: "help"
  }
];
export const support = [
  // {
  //   title: "Settings ",
  //   id:10  ,
  //   active_icon: settingin ,
  //   inactive_icon:  settingact,
  //   path: "settings"
  // },
  // {
  //   title: "Help ",
  //   id:11 ,
  //   active_icon:helpact  ,
  //   inactive_icon:helpin  ,
  //   path: "help"
  // }
]