import React, { useContext } from "react";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { AppContext } from "./ContextApi/AppContext/AppContext";
import { routerRoutes } from "./Utility/routes";

const router = createBrowserRouter(routerRoutes);

function App() {
  const { theme } = useContext(AppContext);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
