import React from "react";
import Button from "@mui/material/Button";

function ControlledButton({ variant = "contained", style, onClick, ...props }) {
  return (
    <Button onClick={onClick} style={style} variant={variant} {...props}>
      {props.children}
    </Button>
  );
}

export default ControlledButton;
