import React from "react";
import styles from "./TermsConditions.module.css";
function TermsConditions() {
  return (
    <div className={styles.con}>
      <p className={styles.name}>
        Welcome to SAMA! These Terms and Conditions outline the rules and
        regulations for the use of SAMA.
        <br />
        By accessing and using SAMA, you accept these Terms and Conditions in
        full. If you disagree with any part of these terms, please do not
        continue to use our web app.
        <br />
        The following terminology applies to these Terms and Conditions: "User,"
        "You," or "Your" refers to you, the person accessing and using SAMA.
        "We," "Us," or "Our" refers to the company that owns SAMA.
      </p>
      {data.map((item) => {
        return (
          <>
            <p className={styles.name}>{item.head}</p>
            <p className={styles.desc}>{item.desc}</p>
          </>
        );
      })}
    </div>
  );
}

export default TermsConditions;
const data = [
  {
    id: 1,
    head: "1. Use of Web App ",
    desc: `Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.
  Aenean vitae elit sed sem tempor tempus sed eu nulla. Cras molestie ultricies est ut venenatis. Fusce felis risus, fringilla at leo vitae, lobortis gravida sapien. Etiam mollis faucibus magna. Donec fringilla mattis augue pulvinar porta. Maecenas pretium urna leo, in vestibulum ipsum dictum quis. Pellentesque interdum ullamcorper ligula, nec venenatis odio laoreet vitae.`,
  },
  {
    id: 2,
    head: " 2. Intellectual Property Rights",
    desc: `Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.`,
  },
  {
    id: 3,
    head: "3. User Content ",
    desc: " Praesent iaculis elementum ullamcorper. Donec eget euismod mauris. Vivamus eu leo ornare, tempor lorem eget, cursus mauris. Proin condimentum ornare facilisis. Fusce sapien nisl, laoreet sed vehicula et, cursus sed dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. In gravida, quam quis dignissim interdum, felis metus maximus lectus, nec ullamcorper velit nunc consectetur lectus. Etiam in leo orci. Nullam non sagittis nunc. Nulla pretium tempus est, faucibus varius felis faucibus ac. Integer id commodo ligula. Proin eu dapibus nibh. Donec a porttitor nisl.",
  },
];
