import React from "react";
import styles from "./Notifications.module.css";
import { FormControlLabel, Switch } from "@mui/material";
function Notifications() {
  return (
    <div className={styles.con}>
      <h3 className={styles.heading}>Notification</h3>
      <div className={styles.notifications}>
        {data?.slice(0, 4)?.map((item) => {
          return (
            <div className={styles.notes}>
              <div className={styles.left}>
                <p className={styles.name}>{item.name}</p>
                <p className={styles.desc}>{item.desc}</p>
              </div>
              <FormControlLabel control={<Switch defaultChecked />} />
            </div>
          );
        })}
        <h3 className={styles.heading}>Email Notifications</h3>
        {data?.slice(4)?.map((item) => {
          return (

            <div className={styles.notes}>
            <div className={styles.left}>
              <p className={styles.name}>{item.name}</p>
              <p className={styles.desc}>{item.desc}</p>
            </div>
            <FormControlLabel control={<Switch defaultChecked />} />
          </div>
          )
        })}
      </div>
    </div>
  );
}

export default Notifications;
const data = [
  {
    id: 1,
    name: "Intervention Notification ",
    desc: " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature",
  },
  {
    id: 2,
    name: "Scorecard Notification ",
    desc: " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature ",
  },
  {
    id: 3,
    name: " Respondents Notification",
    desc: " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature",
  },
  {
    id: 4,
    name: " New Solutions",
    desc: " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature",
  },
  {
    id: 5,
    name: " Announcement & Updates",
    desc: " Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature ",
  },
];
