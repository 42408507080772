import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import { Button, MenuItem, MenuList, Select, TextField } from "@mui/material";
import { PostApi } from "../../Utility/network";
import ACK from "../ACK/ACK";
function ContactUs() {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    subject: "",
  });
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    company_name: "",
    question: " ",
  });
  const data = ["Inquiry about services", "Inquiry about other"];
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const postData = async () => {
    const contact = {
      name: formValue?.name,
      email: formValue?.email,
      mobile: formValue?.mobile,
      subject: selectedValue?.subject,
      company_name: formValue?.company_name,
      question: formValue?.question,
    };
    try {
      const getResponse = await PostApi(`profile/contact_us/`, contact);
      console.log(getResponse, "bfkjhkjhfkj");
    } catch (error) {}
  };

  const handleChangeINput = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleSelect = (event) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      subject: event.target.value,
    }));
  };
  const clearState = () => {
    setFormValue({
      name: "",
      email: "",
      mobile: "",
      subject: "",
      company_name: "",
      question: " ",
    });
  };

  return (
    <div className={styles.container}>
      <p>Didn't find the answer you are looking for ?</p>
      <h3 className={styles.head}>Contact Us </h3>

      <div className={styles.top}>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Your Name</p>
          <TextField
            type="text"
            placeholder=" Your Name"
            size="small"
            style={{ minWidth: "100%" }}
            name="name"
            value={formValue?.name}
            onChange={handleChangeINput}
          />
        </div>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Email</p>
          <TextField
            type="text"
            placeholder="Email "
            size="small"
            style={{ minWidth: "100%" }}
            name="email"
            value={formValue?.email}
            onChange={handleChangeINput}
          />
        </div>
      </div>
      <div className={styles.top}>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Mobile Number</p>
          <TextField
            type="text"
            placeholder="Mobile Number "
            size="small"
            style={{ minWidth: "100%" }}
            name="mobile"
            value={formValue?.mobile}
            onChange={handleChangeINput}
          />
        </div>
        <div className={styles.fieldCon}>
          <p className={styles.name}>Company Name </p>
          <TextField
            type="text"
            placeholder="Company Name  "
            size="small"
            style={{ minWidth: "100%" }}
            name="company_name"
            value={formValue?.company_name}
            onChange={handleChangeINput}
          />
        </div>
      </div>

      <div className={styles.fieldCon}>
        <p className={styles.name}>Subject</p>

        <Select
          size="small"
          style={{ width: "100%", height: "50px" }}
          onChange={handleSelect}
          value={selectedValue.subject}
        >
          {data?.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.fieldCon}>
        <p className={styles.name}>Your Question </p>
        <textarea
          type="text"
          placeholder=" Your Question "
          style={{ width: "100%", minHeight:"110px" }}
          name="question"
          value={formValue?.question}
          onChange={handleChangeINput}
        />
      </div>
      <div className={styles.btn}>
        <Button
          variant="contained"
          onClick={() => {
            postData();
            clearState();
            handleOpen();
          }}
        >
          Submit
        </Button>
      </div>
      {open && <ACK open={open} handleClose={handleClose} />}
    </div>
  );
}

export default ContactUs;
