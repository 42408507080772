import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { createTheme } from "@mui/material/styles";
import {
  LOCAL_STRING,
  REMEMBER,
  SURVEY,
  ALLSECTION,
  CURRENTSECTION,
  SURVEYPAGE,
  TOKEN,
  USER,
  SELECTEDSURVEY,
  ORGID,
} from "../../Utility/strings";
import { PRIMARYCOLOR, SECONDARYCOLOR } from "../../Utility/constants";
import Cookies from "js-cookie";
import { dycryptAES, encryptAES } from "../../Utility/crypt";
import api from "../../Utility/network";
import { check } from "./check";

export const AppContext = createContext(null);

const cookieValue = Cookies.get(LOCAL_STRING);
const sessionValue = sessionStorage.getItem(LOCAL_STRING);

const themes = createTheme({
  palette: {
    primary: { 500: PRIMARYCOLOR },
    secondary: { A400: SECONDARYCOLOR },
    white: { 500: "rgba(255,255,255)" },
    mode: "light",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

function getData(keyname, value) {
  let decryptedData = null;
  try {
    decryptedData = dycryptAES(value);
  } catch {}
  return checkObject(keyname, decryptedData);
}

function checkObject(keyname, decryptedData) {
  if (decryptedData) {
    if (decryptedData[keyname]) {
      return decryptedData[keyname];
    }
  }
  if (keyname === TOKEN) return { access: "", refresh: "" };
  if (keyname === USER) return "";
  if (keyname === REMEMBER) return false;
  if (keyname === SURVEYPAGE) return { count: "0" };
  if (keyname === SURVEY)
    return {
      end_date: "",
      id: null,
      language: null,
      location: null,
      organisation: null,
      start_date: null,
      thumbnail: null,
      title: "",
      thumbnail_id: null,
    };
  if (keyname === ALLSECTION)
    return [
      {
        survey_id: null,
        survey_name: "",
        title: "",
        questions: [],
        index: null,
      },
    ];
  if (keyname === CURRENTSECTION)
    return {
      survey_id: null,
      survey_name: "",
      title: "",
      questions: [],
      index: null,
    };
  if (keyname === SELECTEDSURVEY) return { id: null, title: "" };
  return "";
}

function AppContextProvider(props) {
  const [state, setState] = useState({
    theme: themes,
    token: getData(TOKEN, cookieValue || sessionValue),
    user: getData(USER, cookieValue || sessionValue),
    remember: getData(REMEMBER, cookieValue || sessionValue),
    survey: getData(SURVEY, cookieValue || sessionValue),
    allSection: getData(ALLSECTION, cookieValue || sessionValue),
    currentSection: getData(CURRENTSECTION, cookieValue || sessionValue),
    surveyPage: getData(SURVEYPAGE, cookieValue || sessionValue),
    selectedSurvey: getData(SELECTEDSURVEY, cookieValue || sessionValue),
    searchValue: "",
    orgId: getData(ORGID, cookieValue || sessionValue),
  });
  console.log(state, "surveys");
  useLayoutEffect(() => {
    const authInterceptor = api.interceptors.request.use(
      (config) => {
        // Modify the request config (e.g. add authorization headers)
        const { token } = state;
        if (token && token.access) {
          config.headers.Authorization = `Bearer ${token.access}`;
        }
        if (config.data) {
          config.data =
            typeof config.data === "object"
              ? encryptAES(config.data)
              : config.data;
          // various ways to transform data

          // config.data.newProperty = 'newValue';

          // config.data = {
          //     ...config.data,
          //     transformedProperty: config.data.originalProperty * 2,
          // };
        }
        return config;
      },
      (error) => {
        // Handle request errors
        return Promise.reject(error);
      }
    );
    return () => {
      api.interceptors.request.eject(authInterceptor);
    };
  }, [state.token]);

  useEffect(() => {
    const { theme, ...restState } = state;
    const encryptedData = encryptAES(restState);
    if (!state.remember) {
      try {
        sessionStorage.setItem(LOCAL_STRING, encryptedData);
      } catch (error) {
        console.log(error);
      }
    } else {
      Cookies.set(LOCAL_STRING, encryptedData, { expires: 1 });
    }
  }, [state]);

  const updateState = (key, data) => {
    if (key === REMEMBER) {
      data === true ? sessionStorage.clear() : Cookies.remove(LOCAL_STRING);
    }
    setState((prev) => ({ ...prev, [key]: data }));
  };

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove(LOCAL_STRING);
    window.location.href = "/login";
  };
  check();
  const setSearchValue = (data) => {
    setState((prev) => ({
      ...prev,
      searchValue: data,
    }));
  };

  return (
    <AppContext.Provider
      value={{
        theme: state.theme,
        setState: updateState,
        handleLogout: handleLogout,
        token: state.token,
        surveyQuestions: state.currentSection,
        setContext: setState,
        contextState: state,
        surveyPage: state.surveyPage,
        setSearchValue: setSearchValue,
        searchValue: state.searchValue,
        orgId: state.orgId,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
