import React, { useContext, useEffect, useState } from "react";
import styles from "./CreateSurveyPop.module.css";
import cross from "../../assets/componentAssest/cross.png";
import Thumbnail1 from "../../assets/componentAssest/Thumbnail1.png";
import Thumbnail2 from "../../assets/componentAssest/Thumbnail2.png";
import Thumbnail3 from "../../assets/componentAssest/Thumbnail3.png";
import search from "../../assets/componentAssest/search.png";
import { Dialog } from "@mui/material";
import { getApi } from "../../Utility/network";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

function CreateSurveyPop({ handleClose, open }) {
  const { setContext, orgId } = useContext(AppContext);
  const [survey, setSurvey] = useState();
  const [searchs, setSearch] = useState("");
  const navigate = useNavigate();
  const getData = async (id) => {
    if (!id) {
      return;
    }
    try {
      const data = await getApi(
        `/survey?page_size=20&organisation=${id}&q=${searchs}`
      );

      setSurvey(data?.data?.data);
    } catch (error) {}
  };

  const getProfileData = async () => {
    try {
      const getResponse = await getApi(`auth/user/me`);
      console.log(getResponse, "bfkjhkjhfkj");
      getData(getResponse?.data?.profile?.organisation?.id);
      setContext((prev) => ({
        ...prev,
        orgId: getResponse?.data?.profile?.organisation?.id,
      }));
      // setOrgId();
    } catch (error) {}
  };
  useEffect(() => {
    getData(orgId);
  }, [searchs]);
  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
      <div className={styles.create}>
        <div className={styles.headcreate}>
          <h3 className={styles.headingcreate}>Select Survey to continue</h3>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={cross}></img>
          </div>
        </div>
        <div className={styles.search}>
          <input
            type="text"
            placeholder="Search Survey"
            value={searchs}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div>
            <img src={search}></img>
          </div>
        </div>

        <div className={styles.imgInfo}>
          {/* {Create?.map((item) => {
            console.log(item,'item in here') */}
          {/* return ( */}
          <>
            {survey?.map((item) => {
              return (
                <div
                  className={styles.createImg}
                  onClick={() => {
                    setContext((prev) => ({
                      ...prev,
                      selectedSurvey: item,
                    }));
                    handleClose();
                  }}
                >
                  <img
                    src={item?.thumbnail?.image}
                    className={styles.img}
                  ></img>
                  <p>{item.title}</p>
                </div>
              );
            })}
          </>
          {/* );
          })} */}
        </div>
        {/* <div className={styles.buttons}>
        <button>Add Survey</button>
        <button>Close</button>

    </div> */}
      </div>
    </Dialog>
  );
}

export default CreateSurveyPop;
const Create = [
  {
    id: 1,
    img: Thumbnail1,
    name: "Thumbnail 1",
    desc: "Diagnostic_Baseline_Infosys_17.6.2024",
  },
  {
    id: 2,
    img: Thumbnail2,
    name: "Thumbnail 2",
    desc: "Diagnostic_Endline_Infosys_30.9.2023",
  },
  {
    id: 3,
    img: Thumbnail3,
    name: "Thumbnail 3",
    desc: "Diagnostic_Baseline_Infosys_14.1.2023",
  },
];
