import React from "react";
import styles from "../Onboarding.module.css";
import { PRIMARYCOLOR } from "../../Utility/constants";

function ImageryText({ image, primaryText, showBorder = true, ...props }) {
  return (
    <div>
      <div style={{width:"100px"}}>
        <img
          alt="icon"
          src={image}
          style={{
            border: showBorder ? `1px solid ${PRIMARYCOLOR}` : "none",
            padding: "5px",
            // borderRadius: "50px",
            width:"100%"
          }}
        />
      </div>
      <p className={styles.logTitle}>{primaryText}</p>
      <p className={styles.logSecTitle}>{props.children}</p>
    </div>
  );
}

export default ImageryText;
