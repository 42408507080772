import React, { useContext, useEffect, useState } from "react";
import styles from "./TurnoverInsights.module.css";
import filterImg from "../../assets/componentAssest/filter.png";
import Filter from "../../Components/Filter/Filter";
import CustomDrawer from "../../Components/CustomDrawer/CustomDrawer";
import { Button, Skeleton } from "@mui/material";
import VerticalChart from "../../Components/VerticalChart/VerticalChart";
import PieChart from "../../Components/PieChart/PieChart";
import { getApi } from "../../Utility/network";
import { useParams } from "react-router-dom";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import CreateSurveyPop from "../../Components/CreateSurveyPop/CreateSurveyPop";

function TurnoverInsights() {
  const { contextState } = useContext(AppContext);
  const { selectedSurvey } = contextState;
  const [open, setOpen] = useState(false);
  const [turnoverData, setTurnoverData] = useState([]);
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    department: [],
    seniority: [],
    gender: [],
    location: [],
  });
  const [dropDown, setDropDown] = useState({
    departmentValue: "",
    seniorityValue: "",
    genderValue: "",
    locationValue: "",
  });
  const reset = () => {
    setDropDown({
      departmentValue: "",
      seniorityValue: "",
      genderValue: "",
      locationValue: "",
    });
  };
  const [popUp, setPopUp] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePopUp = () => {
    setPopUp(!true);
  };
  const stayText = "Reasons to Stay";
  const quitText = "Reasons to Quit";

  useEffect(() => {
    if (selectedSurvey.id !== null) {
      getData(selectedSurvey.id, dropDown);
      getDataFilter(selectedSurvey.id);
    }
  }, [selectedSurvey]);
  console.log(dropDown, "dropDown");
  useEffect(() => {
    if (selectedSurvey.id !== null) {
      const timout = setTimeout(() => {
        const finalFilters = {};

        if (dropDown?.departmentValue) {
          const dept = dropDown?.departmentValue;

          finalFilters.department = dept;
        }

        if (dropDown?.seniorityValue) {
          const seniority = dropDown?.seniorityValue;

          finalFilters.seniority = seniority;
        }

        if (dropDown?.genderValue) {
          const gender = dropDown?.genderValue;

          finalFilters.gender = gender;
        }
        if (dropDown?.locationValue) {
          const location = dropDown?.locationValue;

          finalFilters.location = location;
        }

        // getTopData(selectedSurvey.id, finalFilters);
        // getData(selectedSurvey.id, finalFilters);
      }, 600);
      return () => clearTimeout(timout);
    }
  }, [selectedSurvey, dropDown]);
  const handleData = () => {
    getData(selectedSurvey.id, dropDown);
    handleClose();
  };
  const getData = async (id, arr) => {
    console.log(arr, "arr arr ss");
    const params = {};

    if (arr) {
      let {
        departmentValue: department,
        genderValue: gender,
        locationValue: location,
        seniorityValue: seniority,
      } = arr;

      let arr1 = { department, gender, location, seniority };
      // Function to check if a value is an object
      function isObject(value) {
        return value;
      }

      // Create an empty object to store the URL parameters

      // Iterate over the variables and add them to the parameters object if they are objects
      for (const key in arr1) {
        if (isObject(arr1[key])) {
          params[key] = arr1[key];
        }
      }
    }
    const url = `survey/dashboard/insights/${id}`; // Replace with your actual API endpoint
    const config = {
      params,
    };
    console.log(config, "params");
    if (!id) return;
    setLoading(true);
    try {
      const getResponse = await getApi(url, params);
      setTurnoverData(getResponse.data);
      setLoading(false);
    } catch (error) {}
  };
  console.log(turnoverData, "turnoverData");

  const getDataFilter = async (id) => {
    if (!id) return;

    try {
      const getResponse = await getApi(
        `survey/dashboard/dropdown/${id}?filter_gender=true&filter_department=true&filter_seniority=true&filter_location=true`
      );
      let { departments, genders, seniority_levels, locations } =
        getResponse.data;
      departments = departments?.map((item, index) => {
        return { id: item, name: item };
      });
      seniority_levels = seniority_levels?.map((item, index) => {
        return { id: item, name: item };
      });
      genders = genders?.map((item, index) => {
        return { id: item, name: item };
      });
      locations = locations?.map((item, index) => {
        return { id: item, name: item };
      });
      setFilterValue({
        department: departments,
        seniority: seniority_levels,
        gender: genders,
        location: locations,
      });
    } catch (error) {}
  };
  console.log(filterValue, "data sample");

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <h3 className={styles.heading}>Turnover Insights</h3>
        <div className={styles.filter} onClick={handleOpen}>
          <img src={filterImg} />
        </div>
      </div>
      <div className={styles.selectSurvey}>
        <div>
          <p className={styles.label}>Selected Survey</p>
          <p className={styles.surveyName}>{selectedSurvey.title}</p>
        </div>
        <Button
          variant="text"
          onClick={() => {
            setPopUp(true);
          }}
        >
          Change
        </Button>
      </div>
      {popUp && <CreateSurveyPop open={popUp} handleClose={handleClosePopUp} />}
      <CustomDrawer
        open={open}
        anchor={"right"}
        width={"25%"}
        onClose={handleClose}
      >
        <Filter
          close={handleClose}
          filterData={filter}
          setDropDown={setDropDown}
          filterValue={filterValue}
          dropDown={dropDown}
          turnover={true}
          callBack={handleData}
          reset={reset}
        />
      </CustomDrawer>

      <div className={styles.chartCon}>
        {loading ? (
          <Skeleton variant="rounded" width={"45%"} height={350} />
        ) : (
          <div style={{ width: "48%" }}>
            <VerticalChart
              Chartdata={turnoverData?.reasons_to_stay}
              data={turnoverData?.reasons_to_stay?.slice(0, 5)}
              text={stayText}
              width={80}
              Label={true}
            />
          </div>
        )}
        <div style={{ width: "48%" }}>
          {loading ? (
            <Skeleton variant="rounded" width={"100%"} height={350} />
          ) : (
            <VerticalChart
              Chartdata={turnoverData?.reasons_to_quit}
              data={turnoverData?.reasons_to_quit?.slice(0, 5)}
              text={quitText}
              width={130}
              Label={true}
            />
          )}
        </div>
      </div>
      <div className={styles.turnover}>
        <h3 className={styles.turnHeading}>Turnover in less than 1 year</h3>
        <div className={styles.InfoCon}>
          <PieChart
            value={turnoverData.turnover_in_less_than_1_year?.percentage}
            color={"#4DD0E1"}
            size={250}
            Font={"40px"}
            Thikness={8}
          />
          <div>
            {turnoverData.turnover_in_less_than_1_year?.statements.map(
              (item) => {
                return <p className={styles.listItem}>{item}</p>;
              }
            )}

            <p className={styles.details}>
              {turnoverData.turnover_in_less_than_1_year?.summary_statement}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnoverInsights;
