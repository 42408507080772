import React, { useContext, useEffect, useState } from "react";
import styles from "./ScoreChartCom.module.css";
import filterImg from "../../assets/componentAssest/filter.png";
import { Button, Skeleton } from "@mui/material";
import ScoreCom from "../ScoreCom/ScoreCom";
import OrgnizationProgress from "../OrgnizationProgress/OrgnizationProgress";
import Filter from "../Filter/Filter";
import CustomDrawer from "../CustomDrawer/CustomDrawer";
import scoreIMg from "../../assets/componentAssest/scoreIMg.svg";
import scoreIMg1 from "../../assets/componentAssest/scoreIMg1.svg";
import scoreIMg2 from "../../assets/componentAssest/scoreIMg2.svg";
import { getApi, PostApi } from "../../Utility/network";
import { useParams } from "react-router-dom";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import DataTable from "../DataTable/DataTable";
import EmptyPage from "../EmptyPage/EmptyPage";
import scoreCard from "../../assets/componentAssest/scoreCard.png";
import { nil } from "ajv";
const formatCellContent = (text) => {
  if (!text) return " -";
  const [percentage, ...rest] = text.split(" ");
  const restText = rest.join(" ");
  return (
    <div style={{ textAlign: "start" }}>
      <span style={{ color: "#101828", fontWeight: "400", fontSize: "14px" }}>
        {percentage}
      </span>
      <br />
      <span>{restText}</span>
    </div>
  );
};
function ScoreChartCom() {
  const { contextState } = useContext(AppContext);
  const { selectedSurvey } = contextState;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataAPI, setDataAPI] = useState([]);
  const [topData, setTopData] = useState();
  const [empty, setEmpty] = useState(false);
  const [open, setOpen] = useState(null);
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    department: [],
    seniority: [],
    gender: [],
    location: [],
  });
  const [dropDown, setDropDown] = useState({
    departmentValue: "",
    seniorityValue: "",
    genderValue: "",
    locationValue: "",
  });
  const reset = () => {
    setDropDown({
      departmentValue: "",
      seniorityValue: "",
      genderValue: "",
      locationValue: "",
    });
  };
  const handleClick = (id) => {
    setOpen(id === open ? null : id);
  };
  useEffect(() => {
    if (selectedSurvey.id !== null) {
      const timout = setTimeout(() => {
        const finalFilters = {};

        if (dropDown?.departmentValue) {
          const dept = dropDown?.departmentValue;

          finalFilters.department = dept;
        }

        if (dropDown?.seniorityValue) {
          const seniority = dropDown?.seniorityValue;

          finalFilters.seniority = seniority;
        }

        if (dropDown?.genderValue) {
          const gender = dropDown?.genderValue;

          finalFilters.gender = gender;
        }
        if (dropDown?.locationValue) {
          const location = dropDown?.locationValue;

          finalFilters.location = location;
        }

        // getTopData(selectedSurvey.id, finalFilters);
        // getData(selectedSurvey.id, finalFilters);
      }, 600);
      return () => clearTimeout(timout);
    }
  }, [selectedSurvey, dropDown]);
  useEffect(() => {
    if (selectedSurvey.id !== null) {
      getData(selectedSurvey.id, dropDown);
      getTopData(selectedSurvey.id, dropDown);
      getDataFilter(selectedSurvey.id);
    }
  }, [selectedSurvey, dropDown]);

  const handleOpen = () => {
    setOpenDrawer(true);
  };
  console.log(loading, "load");

  const getData = async (id, arr) => {
    if (!id) return;
    const params = {};

    if (arr) {
      let {
        departmentValue: department,
        genderValue: gender,
        locationValue: location,
        seniorityValue: seniority,
      } = arr;

      let arr1 = { department, gender, location, seniority };
      // Function to check if a value is an object
      function isObject(value) {
        return value;
      }

      // Create an empty object to store the URL parameters

      // Iterate over the variables and add them to the parameters object if they are objects
      for (const key in arr1) {
        if (isObject(arr1[key])) {
          params[key] = arr1[key];
        }
      }
    }
    const url = `survey/dashboard/scores/${id}`; // Replace with your actual API endpoint
    const config = {
      params,
    };
    try {
      setLoading(true);
      const getResponse = await getApi(url, params);
      console.log(getResponse, "dataScore");

      setDataAPI(getResponse.data);
      setLoading(false);
    } catch (error) {}
  };
  console.log(dataAPI, "dataAPI");
  const getTopData = async (_id, filter) => {
    if (!_id) return;
    const params = {};

    if (filter) {
      let {
        departmentValue: department,
        genderValue: gender,
        seniorityValue: seniority,
        locationValue: location,
      } = filter;

      let arr1 = { department, gender, seniority, location };
      // Function to check if a value is an object
      function isObject(value) {
        return value;
      }

      // Create an empty object to store the URL parameters

      // Iterate over the variables and add them to the parameters object if they are objects
      for (const key in arr1) {
        if (isObject(arr1[key])) {
          params[key] = arr1[key];
        }
      }
    }
    const url = `survey/dashboard/top_level_scores/${_id}`; // Replace with your actual API endpoint
    const config = {
      params,
    };
    try {
      setLoading(true);
      const getResponse = await getApi(url, params);

      setTopData(getResponse.data);
      setLoading(false);
    } catch (error) {}
  };
  console.log(topData, "topData");

  const getDataFilter = async (id) => {
    if (!id) return;
    try {
      const getResponse = await getApi(
        `survey/dashboard/dropdown/${id}?filter_gender=true&filter_department=true&filter_seniority=true&filter_location=true`
      );
      console.log(getResponse, "response21212");
      let { departments, genders, seniority_levels, locations } =
        getResponse.data;
      departments = departments?.map((item, index) => {
        return { id: item, name: item };
      });
      seniority_levels = seniority_levels?.map((item, index) => {
        return { id: item, name: item };
      });
      genders = genders?.map((item, index) => {
        return { id: item, name: item };
      });
      locations = locations?.map((item, index) => {
        return { id: item, name: item };
      });
      setFilterValue({
        department: departments,
        seniority: seniority_levels,
        gender: genders,
        location: locations,
      });
    } catch (error) {}
  };
  console.log(dropDown, "filterValue");
  const getPdf = async (id) => {
    try {
      const getResponse = await getApi(
        `/survey/dashboard/scorecard_download/${id}`
      );
      console.log(getResponse?.data?.pdf_url, "pdf");
      if (getResponse?.data?.pdf_url) {
        window.open(getResponse?.data?.pdf_url);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const data = [
    {
      id: 1,
      name: "Recognition Score",
      score: topData?.recognition_score,
      img: scoreIMg,
      message:
        "% of employees that feel recognized & valued for their contributions",
    },
    {
      id: 2,
      name: "Belongingness Score",
      score: topData?.belongingness_score,
      img: scoreIMg1,
      message:
        "% of employees that feel that they belong to the organization and do things beyond expectations",
    },
    {
      id: 3,
      name: "Allyship Score",
      score: topData?.allyship_score,
      img: scoreIMg2,
      message:
        "% of employees that feel that someone has their back and they can rely on someone for their wellbeing",
    },
  ];
  
  const data1 = [
    {
      id: 1,
      color: "#F67D7D",
      value: dataAPI?.gender_inclusion_score?.score,
      name: " Gender Inclusion Score",
      desc: "This score demonstrates the commitment and efforts of the organization to support gender equality through policy development, representation, and transparency",
      innerData: dataAPI?.gender_inclusion_score?.statements,
    },
    {
      id: 2,
      color: "#FBD26A",
      value: dataAPI?.career_developement_score?.score,
      name: "Career Development Score",
      desc: `captures the organizations’ commitment and
  investment towards career development of it’s employees`,
      innerData: dataAPI?.career_developement_score?.statements,
    },
    {
      id: 3,
      color: "#92F9C9",
      value: dataAPI?.safety_score?.score,
      name: " Safety Score",
      desc: `is defined as a measure of its commitment to creating a safe and
  healthy work environment for its employees`,
      innerData: dataAPI?.safety_score?.statements,
    },
    {
      id: 4,
      color: "#FBD26A",
      value: dataAPI?.flexibility_score?.score,
      name: " Flexibility Score",
      desc: `captures the extent to which an organization allows and
  encourages its employees to have flexibility in their work arrangements.`,
      innerData: dataAPI?.flexibility_score?.statements,
    },
  ];
 console.log(data1,"data1");
 
  
  const data2 = [
    {
      id: 1,
      color: "#F67D7D",
      value: dataAPI?.gender_inclusion_score?.score,
      name: " Productivity Score",
      desc: "this is a measure of how efficient & productive employees feel at work",
      innerData: dataAPI?.gender_inclusion_score?.statements,
    },
    {
      id: 2,
      color: "#FBD26A",
      value: dataAPI?.health_and_wellness_score?.score,
      name: "Health & Wellness",
      desc: `Captures mental wellbeing and physical fitness of the individual, along with their holistic fitness mindset
  `,
      innerData: dataAPI?.health_and_wellness_score?.statements,
    },
    {
      id: 3,
      color: "#92F9C9",
      value: dataAPI?.ambition_score?.score,
      name: " Ambition Score",
      desc: `This is a  measure of an individual's level of motivation and desire to achieve success in their career and personal life.`,
      innerData: dataAPI?.ambition_score?.statements,
    },
    {
      id: 4,
      color: "#FBD26A",
      value: dataAPI?.financial_independence_score?.score,
      name: "Financial Independence Score",
      desc: `This score investigates the relationship women have with the money they earn.`,
      innerData: dataAPI?.financial_independence_score?.statements,
    },
  ];
  console.log( data2,"data2");
  const handleData = () => {
    getData(selectedSurvey.id, dropDown);
    getTopData(selectedSurvey.id, dropDown);
    setOpenDrawer(false);
  };
  return (
    <>
      {selectedSurvey.id ? (
        <div className={styles.Con}>
          <div className={styles.top}>
            <h3 className={styles.heading}>Scorecard</h3>

            <div className={styles.topCon}>
              <div className={styles.filter} onClick={handleOpen}>
                <img src={filterImg} />
              </div>
              <Button
                variant="contained"
                onClick={() => getPdf(selectedSurvey?.id)}
              >
                Download Data
              </Button>
            </div>
          </div>

          <CustomDrawer
            open={openDrawer}
            anchor={"right"}
            width={"25%"}
            onClose={() => setOpenDrawer(false)}
          >
            <Filter
              close={() => setOpenDrawer(false)}
              dropDown={dropDown}
              setDropDown={setDropDown}
              filterValue={filterValue}
              callBack={handleData}
              reset={reset}
            />
          </CustomDrawer>
          <div className={styles.card}>
            {data?.map((item) => {
              console.log(item,"checkDaTAS");
              
              return loading ? (
                <Skeleton variant="rounded" width={"30%"} height={"100px"} />
              ) : (
                <ScoreCom
                  data={item}
                  loading={loading}
                  open={open === item.id}
                  handleClick={handleClick}
                />
              );
            })}
          </div>

          <OrgnizationProgress
            heading={" Organizational Culture & Environment"}
            data={data1}
            loading={loading}
          />
          <OrgnizationProgress
            heading={"Employee Support & Development "}
            data={data2}
            loading={loading}
          />

          {/* <DataTable
            rows={rows}
            columns={columns}
            formatCellContent={formatCellContent}
          /> */}
        </div>
      ) : (
        <EmptyPage
          headerText={
            "Not everything that can be counted counts; not everything that counts can be counted    Albert Einstien"
          }
          descText={"We are awaiting data to arrive to build the scorecard."}
          pageHeading={"Scorecard"}
          image={scoreCard}
        />
      )}
    </>
  );
}

export default ScoreChartCom;
const rows = [
  { id: 1, name: "Recognition Score", per: "65%" },
  { id: 2, name: "Belongingness Score", per: "65%" },
  { id: 3, name: "Allyship Score", per: "65%" },
  {
    id: 4,
    name: "Gender Inclusion Score",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
  {
    id: 5,
    name: "Career Development Score",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
  {
    id: 6,
    name: "Safety Score",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
  {
    id: 7,
    name: "Flexibility Score",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
  {
    id: 8,
    name: "Productivity Score",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
  {
    id: 9,
    name: "Health & Wellness",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
  {
    id: 10,
    name: "Ambition Score",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
  {
    id: 11,
    name: "Financial Independence Score",
    per: "29%",
    insigth: `96%  Very high level of motivation`,
    forth: `96%  Very high level of motivation`,
    fifth: `96%  Very high level of motivation`,
  },
];
const columns = [
  { key: "name", heading: "Score Name" },
  { key: "per", heading: "%" },
  { key: "insigth", heading: "Insights" },
  { key: "forth", heading: " " },
  { key: "fifth", heading: " " },
];
