export const LOGIN = "/auth/login/";
export const SEND_EMAIL_OTP = "/auth/forget_password/send_email_otp/";
export const VALIDATE_OTP = "/auth/forget_password/validate_otp/";
export const SIGN_UP_2 = "/auth/forget_password/3/set_password/";

export const SIGNUP = "/auth/sign_up/";
export const apiCallsConfig = {
  login: {
    endpoint: LOGIN,
  },
  sendOTP: {
    endpoint: SEND_EMAIL_OTP,
  },
  validateOTP: {
    endpoint: VALIDATE_OTP,
  },
  resetPassword: {
    endpoint: SIGN_UP_2, // Double-check if this endpoint is correct
  },
  signUp: {
    endpoint: SIGNUP,
  },
};
