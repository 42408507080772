import React, { useContext, useEffect, useState } from "react";
import RecommendedPopUP from "../../Components/RecommendedPopUP/RecommendedPopUP";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import { getApi } from "../../Utility/network";
import RecommendedCards from "../../Components/RecommendedCards/RecommendedCards";
import styles from "./RecommendedSolutions.module.css";
import lightHouse from "../../assets/componentAssest/lightHouse.png";
import { AppContext } from "../../ContextApi/AppContext/AppContext";

function RecommendedSolutions() {
  const { contextState } = useContext(AppContext);
  const { selectedSurvey } = contextState;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [popData, setPopData] = useState({
    id: 1,
    description: " ",
    video: "",
  });
  const getData = async () => {
    try {
      const getResponse = await getApi(
        `/survey/recommended_solutions/organisation_assigned/`
      );
      console.log(getResponse, "gsjahsg");
      setData(getResponse.data);
    } catch (error) {}
  };

  useEffect(() => {
    getData(selectedSurvey.id);
  }, []);

  const handleClick = (item) => {
    setPopData(item);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(data,"popData");
  
  return (
    <div>
      {selectedSurvey.id ? (
        <div className={styles.con}>
          <p className={styles.heading}>Recommended Solutions</p>
          <div className={styles.CrarCon}>
            {data.map((item) => {
              return (
                <div
                  onClick={() => {
                    handleClick(item);
                    handleOpen();
                  }}
                  className={styles.cards}
                >
                  <RecommendedCards data={item} />
                </div>
              );
            })}
          </div>
          {open && (
            <RecommendedPopUP
              data={popData}
              open={open}
              handleClose={handleClose}
            />
          )}
        </div>
      ) : (
        <EmptyPage
          pageHeading={"Recommended Solutions"}
          image={lightHouse}
          headerText={
            "When you find no solution to a problem, it's not a problem to be solved yet"
          }
          descText={
            "We are waiting for data & insights before recommending solutions"
          }
        />
      )}
    </div>
  );
}

export default RecommendedSolutions;
