import React, { useState } from "react";
import styles from "./OrgnizationProgress.module.css";
import AmbitionScore from "../AmbitionScore/AmbitionScore";
import PieChart from "../PieChart/PieChart";
import arrow from "../../assets/componentAssest/arrow.svg";
import { truncateText } from "../../Utility/helper";
import { Skeleton } from "@mui/material";
function OrgnizationProgress({ heading, data, loading }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    value: "",
    name: "",
    innerData: [],
    desc: "",
  });
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (data) => {
    console.log(data, "checking123");

    setOpen(true);
    setValue({
      value: data?.value,
      name: data?.name,
      innerData: data?.innerData,
      desc: data?.desc,
    });
  };
  console.log(value, "checkDarta");

  return (
    <>
      <div className={styles.con}>
        {loading ? (
          <Skeleton variant="rounded" animation="wave" width={"23%"} />
        ) : (
          <h3 className={styles.heading}> {heading}</h3>
        )}

        <div className={styles.subCon}>
          {data?.map((item, index) => (
            <React.Fragment key={index}>
              {loading ? (
                <Skeleton variant="rounded" width={"23%"} height={"200px"} />
              ) : (
                <div
                  className={styles.container}
                  onClick={() => handleOpen(item)}
                >
                  <div className={styles.left}>
                    <div className={styles.topCard}>
                      <PieChart
                        value={item.value}
                        color={item.color}
                        size={60}
                      />
                      <div className={styles.right}>
                        <img src={arrow} alt="Arrow" />
                      </div>
                    </div>
                    <p className={styles.heading}>{item.name}</p>
                    <p className={styles.desc}>{truncateText(item.desc, 80)}</p>
                  </div>
                </div>
              )}
              {open && (
                <AmbitionScore
                  open={open}
                  handleClose={handleClose}
                  data={value}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* {open && <AmbitionScore open={open} handleClose={handleClose} />} */}
    </>
  );
}

export default OrgnizationProgress;
