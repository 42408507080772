import React from "react";
import smsNotification from "../../assets/onboarding/smsNotification.svg";
import styles from "../Onboarding.module.css";
import ImageryText from "../ImageryText/ImageryText";
import VerificationCode from "../VerificationCode/VerificationCode";
import ControlledButton from "../../Components/ControlledButton/ControlledButton";
import BackIconComponent from "../BackIconComponent";
import CircularLoading from "../../Components/CircularLoading/CircularLoading";
import { PRIMARYCOLOR } from "../../Utility/constants";

function Step2({ id, handleClick, formState, fieldsData, ...props }) {
  return (
    <div className={styles.stepContainer}>
      <ImageryText image={smsNotification} primaryText="Enter Your Code">
        <span>
          We sent a code to <b>{fieldsData["email"].value}</b>
        </span>
      </ImageryText>
      <div className={styles.formSection}>
        <VerificationCode {...fieldsData["otp"]} />
        <p style={{ marginTop: "8px", color: "#ef5350" }}>{formState.error}</p>
        <div className={styles.secondaryDiv}>
          <div style={{ marginTop: "8px" }}>
            <span style={{ color: "grey", fontSize: "14px" }}>
              Didn't receive the Code?
            </span>{" "}
            <span
              className={styles.blueText}
              style={{ color: PRIMARYCOLOR }}
              onClick={() => {
                handleClick(id, "apicall");
              }}
            >
              Click to resend
            </span>
          </div>
        </div>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px" }}
          onClick={() => {
            handleClick(id, "apicall");
          }}
          disabled={formState.loading}
        >
          {formState.loading && (
            <CircularLoading size={20} style={{ marginRight: "15px" }} />
          )}{" "}
          Continue
        </ControlledButton>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px", color: "black" }}
          variant="outlined"
          onClick={() => {
            handleClick(id, "redirection");
          }}
        >
          <BackIconComponent />
        </ControlledButton>
      </div>
    </div>
  );
}

export default Step2;
