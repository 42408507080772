import React, { useContext, useEffect, useState } from "react";
import styles from "./Filter.module.css";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { getApi } from "../../Utility/network";

function Filter({
  close,
  setDropDown,
  dropDown,
  filterValue,
  turnover = false,
  callBack = () => {},
  reset,
}) {
  const { contextState } = useContext(AppContext);
  const { selectedSurvey } = contextState;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDropDown({
      ...dropDown,
      [name]: value,
    });
  };
  console.log(dropDown, "drop");

  return (
    <div className={styles.container}>
      <div className={styles.topCon}>
        <div className={styles.top}>
          <h3 className={styles.heading}>Filter</h3>
          <CloseIcon onClick={close} style={{ cursor: "pointer" }} />
        </div>

        <div className={styles.fields}>
          <div className={styles.fieldCon}>
            <p className={styles.name}>Location</p>
            <Select
              value={dropDown?.locationValue || ""}
              onChange={handleChange}
              sx={{ width: "100%" }}
              size="small"
              name="locationValue"
              IconComponent={KeyboardArrowDownIcon}
            >
              {filterValue?.location?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className={styles.fieldCon}>
            <p className={styles.name}>Department</p>
            <Select
              value={dropDown?.departmentValue || ""}
              onChange={handleChange}
              sx={{ width: "100%" }}
              size="small"
              name="departmentValue"
              IconComponent={KeyboardArrowDownIcon}
            >
              {filterValue?.department?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}>Seniority</p>
            <Select
              value={dropDown?.seniorityValue || ""}
              onChange={handleChange}
              sx={{ width: "100%" }}
              size="small"
              name="seniorityValue"
              IconComponent={KeyboardArrowDownIcon}
            >
              {filterValue?.seniority?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={styles.fieldCon}>
            <p className={styles.name}>Gender</p>
            <Select
              value={dropDown?.genderValue || ""}
              onChange={handleChange}
              sx={{ width: "100%" }}
              size="small"
              name="genderValue"
              IconComponent={KeyboardArrowDownIcon}
            >
              {filterValue?.gender?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        <Button
          variant="contained"
          onClick={() => {
            callBack();
            close();
          }}
        >
          Apply Filter
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            reset();
            close();
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
}

export default Filter;
