import React, { useContext, useEffect, useState } from "react";
import SelectSurvey from "../../Components/SelectSurvey/SelectSurvey";
import { getApi } from "../../Utility/network";
import TopCard from "./Components/TopCard";
import SummaryCard from "./Components/SummaryCard/SummaryCard";
import SelectedSurvey from "./Components/SelectedSurvey/SelectedSurvey";
import SplitWise from "./Components/SplitWise/SplitWise";
import { useNavigate } from "react-router-dom";
import CreateSurveyPop from "../../Components/CreateSurveyPop/CreateSurveyPop";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import scoreCard from "../../assets/componentAssest/scoreCard.png";
import styles from "./Dashboard.module.css";
import { MenuItem, Select, Skeleton } from "@mui/material";

function Dashboard(props) {
  const { contextState } = useContext(AppContext);
  const { selectedSurvey } = contextState; // id for selected survey
  const [sample, setSample] = useState({
    industry_benchmark: "_",
    projected_hiring_cost: "_",
    turnover_intention: "_",
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [dropDown, setDropDown] = useState({
    genderValue: "",
    monthsValue: "",
  });

  const [filterValue, setFilterValue] = useState({
    gender: [],
    time: [],
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDropDown({
      ...dropDown,
      [name]: value,
    });
  };
  console.log(dropDown, "deptValue");

  useEffect(() => {
    if (selectedSurvey.id !== null) {
      getCards(selectedSurvey.id);
    } else {
      setOpen(true);
    }
  }, [selectedSurvey, dropDown]);
  console.log(selectedSurvey.id, "jhfkjhsde");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getCards = async (_id) => {
    console.log(_id, "_id_id");
    if (!_id) return;
    const filter = {};

    if (dropDown?.genderValue && dropDown.genderValue !== "all") {
      filter.gender = dropDown.genderValue;
    }

    if (dropDown?.monthsValue && dropDown.monthsValue !== "all") {
      filter.time_frame = dropDown.monthsValue;
    }
    try {
      setLoading(true);
      const getResponse = await getApi(`survey/dashboard/cards/${_id}`, filter);
      setSample(getResponse.data);
      console.log(getResponse, "hsfhjgsf");
      setLoading(false);
    } catch (error) {
      console.log(error, "getResponse");
    }
  };
  const getFilters = async (id) => {
    if (!id) return;
    try {
      const getResponse = await getApi(
        `survey/dashboard/dropdown/${id}?filter_gender=true&filter_time_frame=true`
      );
      console.log(getResponse?.data, "filter");
      let { genders, time_frames } = getResponse.data;

      genders = genders?.map((item, index) => {
        return { id: item, name: item };
      });
      genders.unshift({ id: "all", name: "All" });
      time_frames = time_frames?.map((item, index) => {
        return { id: item, name: item };
      });
      time_frames.unshift({ id: "all", name: "All" });
      setFilterValue({
        gender: genders,
        time: time_frames,
      });
    } catch (error) {}
  };
  console.log(filterValue.time, "time");

  useEffect(() => {
    getFilters(selectedSurvey?.id);
  }, [selectedSurvey?.id]);
  const cardData = [
    {
      title: "Turnover Intention",
      id: 1,
      value: sample?.turnover_intention,
      growth: "40%",
      type: true,
    },
    {
      title: "Industry BenchMark",
      id: 2,
      value: sample?.industry_benchmark,
      growth: "20%",
      type: true,
    },
    {
      title: "Projected Hiring Costs",
      id: 3,
      value: sample?.projected_hiring_cost,
      growth: "10%",
      type: false,
    },
  ];
  return (
    <>
      {selectedSurvey.id == null ? (
        <>
          <EmptyPage
            headerText={
              "Not everything that can be counted counts; not everything that counts can be counted. — Albert Einstein"
            }
            descText={"We are awaiting data to arrive to build the dashboard."}
            pageHeading={"Scorecard"}
            image={scoreCard}
          />

          {open && <CreateSurveyPop open={open} handleClose={handleClose} />}
        </>
      ) : (
        <div>
          {open && <CreateSurveyPop open={open} handleClose={handleClose} />}

          <TopCard />
          <SelectedSurvey handleOpen={handleOpen} data={selectedSurvey} />
          <div className={styles.filters}>
            <h2 className={styles.name}>Summary</h2>
            <div className={styles.filterBy}>
              <p className={styles.filter}>Filter by:</p>
              <div className={styles.fieldCon}>
                <Select
                  value={dropDown?.genderValue || "all"}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  size="small"
                  name="genderValue"
                >
                  {filterValue?.gender.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={styles.fieldCon}>
                <Select
                  value={dropDown?.monthsValue || "all"}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  size="small"
                  name="monthsValue"
                >
                  {filterValue?.time?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {cardData.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: `0px 5px 15px 0px #27305014`,
                  padding: "10px",
                  flex: "1 1 0",
                  marginRight: "10px",
                  borderRadius: "10px",
                }}
              >
                {loading ? (
                  <Skeleton variant="rounded" width={"100%"} height={200} />
                ) : (
                  <>
                    <div className={styles.text}>
                      <h5 className={styles.summuryHead}>
                        {item.title.toUpperCase()}
                      </h5>
                      <p
                        style={{ fontWeight: "900" }}
                        className={styles.cardCost}
                      >
                        {item?.title === "Projected Hiring Costs"
                          ? `₹ ${item.value}`
                          : `${item.value}%`}
                      </p>
                    </div>
                    <div className={styles.graph}>
                      <SummaryCard type={item.type} index={index} />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <SplitWise time={filterValue?.time} />
        </div>
      )}
    </>
  );
}

export default Dashboard;
