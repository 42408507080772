import { useState } from "react";

const useInput = (initialValue, validate, props) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");
  const [validationStatus, setValidationStatus] = useState({});

  const handleChange = (e) => {
    const eventValue = e && e.target ? e.target.value : e;
    const newValue = eventValue;
    setValue(newValue);

    if (validate) {
      const { error: validationError, status } = validate(newValue);
      setError(validationError);
      setValidationStatus(status);
    }
  };

  const setErrorExternally = (errorMessage) => {
    setError(errorMessage);
  };

  const setExternalValue = (externalValue) => {
    if (typeof externalValue === "function") {
      externalValue = externalValue(value);
      setValue(externalValue);
    } else {
      setValue(externalValue);
    }
  };

  return {
    value,
    onChange: handleChange,
    error,
    validationStatus,
    setErrorExternally,
    setExternalValue,
    ...props,
  };
};

export default useInput;
