import React, { useContext, useEffect, useState } from "react";
import styles from "./ScoreSearch.module.css";
import AmbitionScore from "../AmbitionScore/AmbitionScore";
import PieChart from "../PieChart/PieChart";
import arrow from "../../assets/componentAssest/arrow.png";
import { truncateText } from "../../Utility/helper";
import { Skeleton } from "@mui/material";
import { getApi } from "../../Utility/network";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import EmptyPage from "../EmptyPage/EmptyPage";
import scoreEmpty from "../../assets/componentAssest/scoreEmpty.svg";
function ScoreSearch() {
  const { contextState, searchValue } = useContext(AppContext);
  const { selectedSurvey } = contextState;
  const [open, setOpen] = useState(false);
  const [dataAPI, setDataAPI] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [value, setValue] = useState({
    value: "",
    name: "",
    innerData: [],
    desc: "",
  });
  const handleClose = () => {
    setOpen(false);
  };
  const getData = async (id) => {
    setLoading(true);
    try {
      const getResponse = await getApi(`survey/dashboard/scores/${id}`);
      console.log(getResponse?.data, "soluctiondata");

      setDataAPI(getResponse?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  useEffect(() => {
    getData(selectedSurvey?.id);
  }, []);
  const handleOpen = (data) => {
    console.log(data, "checking123");

    setOpen(true);
    setValue({
      value: data?.value,
      name: data?.name,
      innerData: data?.innerData,
      desc: data?.desc,
    });
  };
  console.log(dataAPI, "searchValue");
  useEffect(() => {
    if (dataAPI.hasOwnProperty("ambition_score")) {
      const _data = [
        {
          id: 1,
          color: "#F67D7D",
          value: dataAPI?.gender_inclusion_score?.score,
          name: " Gender Inclusion Score",
          desc: "This score demonstrates the commitment and efforts of the organization to support gender equality through policy development, representation, and transparency",
          innerData: dataAPI?.ambition_score?.statements,
        },
        {
          id: 2,
          color: "#FBD26A",
          value: dataAPI?.career_developement_score?.score,
          name: "Career Development Score",
          desc: `captures the organizations’ commitment and
      investment towards career development of it’s employees`,
          innerData: dataAPI?.career_developement_score?.statements,
        },
        {
          id: 3,
          color: "#92F9C9",
          value: dataAPI?.safety_score?.score,
          name: " Safety Score",
          desc: `is defined as a measure of its commitment to creating a safe and
      healthy work environment for its employees`,
          innerData: dataAPI?.safety_score?.statements,
        },
        {
          id: 4,
          color: "#FBD26A",
          value: dataAPI?.flexibility_score?.score,
          name: " Flexibility Score",
          desc: `captures the extent to which an organization allows and
      encourages its employees to have flexibility in their work arrangements.`,
          innerData: dataAPI?.flexibility_score?.statements,
        },

        {
          id: 5,
          color: "#F67D7D",
          value: dataAPI?.gender_inclusion_score?.score,
          name: " Productivity Score",
          desc: "this is a measure of how efficient & productive employees feel at work",
          innerData: dataAPI?.gender_inclusion_score?.statements,
        },
        {
          id: 6,
          color: "#FBD26A",
          value: dataAPI?.health_and_wellness_score?.score,
          name: "Health & Wellness",
          desc: `Captures mental wellbeing and physical fitness of the individual, along with their holistic fitness mindset
      `,
          innerData: dataAPI?.health_and_wellness_score?.statements,
        },
        {
          id: 7,
          color: "#92F9C9",
          value: dataAPI?.ambition_score?.score,
          name: " Ambition Score",
          desc: `This is a  measure of an individual's level of motivation and desire to achieve success in their career and personal life.`,
          innerData: dataAPI?.ambition_score?.statements,
        },
        {
          id: 8,
          color: "#FBD26A",
          value: dataAPI?.financial_independence_score?.score,
          name: "Financial Independence Score",
          desc: `This score investigates the relationship women have with the money they earn.`,
          innerData: dataAPI?.financial_independence_score?.statements,
        },
      ];
      setData(_data);
    }
  }, [dataAPI]);
  const searchString = searchValue.trim().toLowerCase();

  const filteredItems = data.filter(
    (item) => item.name.toLowerCase().includes(searchString)
    // ||
    //   (item.desc && item.desc.toLowerCase().includes(searchString))
  );

  console.log(filteredItems, "filteredItems");

  return (
    <div>
      {filteredItems.length > 0 ? (
        <div>
          <h2>Search Results</h2>
          <div className={styles.con}>
            <div className={styles.subCon}>
              {filteredItems?.map((item, index) => (
                <React.Fragment key={index}>
                  {loading ? (
                    <Skeleton
                      variant="rounded"
                      width={"23%"}
                      height={"200px"}
                    />
                  ) : (
                    <div
                      className={styles.container}
                      onClick={() => handleOpen(item)}
                    >
                      <div className={styles.left}>
                        <div className={styles.topCard}>
                          <PieChart
                            value={item.value}
                            color={item.color}
                            size={60}
                          />
                          <div className={styles.right}>
                            <img src={arrow} alt="Arrow" />
                          </div>
                        </div>
                        <p className={styles.heading}>{item.name}</p>
                        <p className={styles.desc}>
                          {truncateText(item.desc, 80)}
                        </p>
                      </div>
                    </div>
                  )}
                  {open && (
                    <AmbitionScore
                      open={open}
                      handleClose={handleClose}
                      data={value}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          {/* {open && <AmbitionScore open={open} handleClose={handleClose} />} */}
        </div>
      ) : (
        <EmptyPage
          image={scoreEmpty}
          headerText={"No results found"}
          descText={"Perhaps try to use other Keywords"}
        />
      )}
    </div>
  );
}

export default ScoreSearch;
