import React from "react";
import AreaChart from "../../../../Components/AreaChart/AreaChartFill";

function SummaryCard({ type, index, ...props }) {
  return (
    <div style={{ height: "150px", width: "110%" }}>
      <AreaChart type={type} index={index} />
    </div>
  );
}

export default SummaryCard;
