import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const FunnelChart = ({ funnelData }) => {
  const validFunnelData = Array.isArray(funnelData) ? funnelData : [];

  const seriesData = validFunnelData.map((item) =>
    parseFloat(item?.percentage || 0)
  );
  const categoriesData = validFunnelData.map((item) => item?.name || "Unknown");

  const colorPalette = [
    "#4dd0e1",
    "#5fd5e4",
    "#71d9e7",
    "#82deea",
    "#94e3ed",
    "#a6e7f0",
  ];

  const [state, setState] = useState({
    series: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: "80%",
          isFunnel: true,
          distributed: true,
        },
      },
      colors: colorPalette,
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val ? val + "%" : "0";
        },
        dropShadow: {
          enabled: true,
        },
        style: {
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
      xaxis: {
        categories: categoriesData,
      },
      legend: {
        show: false,
      },
    },
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      series: [
        {
          name: "Funnel Series",
          data: seriesData,
        },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          categories: categoriesData,
        },
        tooltip: {
          enabled: false,
        },
      },
    }));
  }, [funnelData]);

  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={500}
        />
      </div>
    </div>
  );
};

export default FunnelChart;
