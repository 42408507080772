import React, { useState } from "react";
import TrainingDrawer from "../../Components/TrainingDrawer/TrainingDrawer";
import Attribution from "../../Components/Attribution/Attribution";
import CustomDrawer from "../../Components/CustomDrawer/CustomDrawer";
import InitativepOpUp from "../../Components/InitativepOpUp/InitativepOpUp";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import Tracking from "../../assets/componentAssest/Tracking.png";
function AttributionTracking() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <EmptyPage
        descText={"Add your first Initiative to start tracking their impact "}
        headerText={"Ouch, It's empty in here!"}
        pageHeading={" Attribution Tracking"}
        image={Tracking}
        buttonShow={true}
        handleOpen={handleOpen}
      />

      <InitativepOpUp open={open} handleClose={handleClose} />
      {/* <Attribution /> */}
    </div>
  );
}

export default AttributionTracking;
