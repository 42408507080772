import React, { useEffect } from "react";
import styles from "./AmbitionScore.module.css";
import PieChart from "../PieChart/PieChart";
import cross from "../../assets/componentAssest/cross.png";
import AmbitionCard from "../AmbitionCard/AmbitionCard";
import { Dialog } from "@mui/material";
function AmbitionScore({ open, handleClose, data }) {
  console.log(data, "heading");

  useEffect(() => {}, [data]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <div className={styles.con}>
        <div className={styles.top}>
          <h3 className={styles.heading}>{data?.name}</h3>
          <div>
            <img
              src={cross}
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <p className={styles.para}>{data?.desc}</p>
        <div className={styles.btmContainer}>
          <div className={styles.left}>
            <PieChart
              value={data?.value}
              color={"#FBD26A"}
              size={"150px"}
              Font={"40px"}
            />
            <p className={styles.subHead}>{data?.name}</p>
          </div>
          <div className={styles.right}>
            <p className={styles.rigthead}>What is affecting this score?</p>
            <div className={styles.cards}>
              {data?.innerData?.map((item) => {
                return <AmbitionCard data={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AmbitionScore;
