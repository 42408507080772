import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";

export default function PieDoughnutChart({ chartData }) {
  const data = [
    { name: "Group A", value: chartData?.Entry?.percentage || 0 },
    { name: "Group B", value: chartData?.["Mid-Senior"]?.percentage || 0 },
    { name: "Group C", value: chartData?.Senior?.percentage || 0 },
  ];

  const totalPercentage = data.reduce((sum, entry) => sum + entry.value, 0);

  const COLORS = ["#AFABAB", "#DA0EB5", "#4DD0E1"];

  return (
    <PieChart width={300} height={350}>
      <Pie
        data={data}
        cx={120}
        cy={200}
        innerRadius={60}
        outerRadius={100}
        fill="#8884d8"
        // paddingAngle={0}
        startAngle={90} // Start from the top
        endAngle={-270}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label
          value={`${totalPercentage}%`}
          position="center"
          fill="black"
          fontSize={24}
          fontWeight="bold"
        />
      </Pie>
    </PieChart>
  );
}
