import React, { useState } from 'react'
import styles from './Attribution.module.css'
import foot from '../../assets/componentAssest/foot.png'
import foot1 from '../../assets/componentAssest/foot1.png'
import foot2 from '../../assets/componentAssest/foot2.png'
import foot3 from '../../assets/componentAssest/foot3.png'
import TrackingPieces from '../TrackingPieces/TrackingPieces'
import CustomDrawer from '../CustomDrawer/CustomDrawer'
import TrainingDrawer from '../TrainingDrawer/TrainingDrawer'


function Attribution() {
    const [openId, setOpenId] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleClick = (id) => {
        if (openId === id) {
            setOpenId(null);
        } else {
            setOpenId(id);
        }
    };
    return (
        <>
            <div className={styles.Con}>

                <div className={styles.top}>
                    <h3>Attribution Tracking</h3>
                    <button className={styles.add} onClick={() => setOpenDrawer(true)} >Add NEW Initiative</button>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.left}>
                        <h4 className={styles.headline} >In-pipeline Interventions</h4>
                        <div className={styles.pieceCon}>
                            {data.map((item) => {
                                return (
                                    <TrackingPieces
                                        key={item.id}
                                        data={item}
                                        open={openId === item.id}
                                        handleClick={() => handleClick(item.id)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.left}>
                        <h4 className={styles.headline}>Ongoing Interventions</h4>
                        <div className={styles.pieceCon}>
                            {data.map((item) => {
                                return (
                                    <TrackingPieces
                                        key={item.id}
                                        data={item}
                                        open={openId === item.id}
                                        handleClick={() => handleClick(item.id)}
                                    />
                                )
                            })}
                        </div>
                    </div>

                    <div className={styles.left}>
                        <h4 className={styles.headline}>Completed Interventions</h4>
                        <div className={styles.pieceCon}>
                            {data.map((item) => {
                                return (
                                    <TrackingPieces
                                        key={item.id}
                                        data={item}
                                        open={openId === item.id}
                                        handleClick={() => handleClick(item.id)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>


            </div>



            <CustomDrawer
                open={openDrawer}
                anchor={"right"}
                width={"35%"}
                onClose={() => setOpenDrawer(false)}
            >
                <TrainingDrawer close={() => setOpenDrawer(false)} />
            </CustomDrawer>

        </>
    )
}

export default Attribution
const data = [
    {
        id: 1,
        img: foot,
        name: "Financial Literacy & Well being "
    },
    {
        id: 2,
        img: foot1,
        name: " Pay Parity Audit "
    },
    {
        id: 3,
        img: foot2,
        name: " Mindful return of parents at work"
    },
    {
        id: 4,
        img: foot3,
        name: "  Pay Parity Audit"
    },
    {
        id: 5,
        img: foot1,
        name: " Building & Nurturing ERGs"
    },
]