import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import useInput from "../hooks/useInput";
import styles from "./Onboarding.module.css";
import { emailValidation } from "../Utility/helper";
import PositionedSnackbar from "../Components/PositionSnackBar/PositionSnackBar";
import { handleApiCall, signUpStepsArray, validatePassword } from "./utility";
import { initialState1, reducer } from "./reducers/formReducer";
import { AppContext } from "../ContextApi/AppContext/AppContext";
import { useNavigate } from "react-router-dom";
import { check } from "../ContextApi/AppContext/check";

function SignUp(props) {
  const navigate = useNavigate();
  const [formState, dispatch] = useReducer(reducer, initialState1);
  const { setContext } = useContext(AppContext);
  const { data } = formState;
  const [step, setStep] = useState(3);
  const email = useInput("", emailValidation); // useInput hook to handle input changes
  const name = useInput("", null);
  const otp = useInput("", null);
  const password = useInput("", null);
  const new_password = useInput("", validatePassword);
  const confirm_password = useInput("", null);
  const designation = useInput("", null);
  const organisation_name = useInput("", null);

  const fieldsData = {
    email,
    password,
    otp,
    new_password,
    confirm_password,
    name,
    organisation_name,
    designation,
  }; // grouping of all fields in one object to access it throughout the pages
  useEffect(() => {
    if (formState.user) {
      const { user } = formState;
      setContext((prev) => ({
        ...prev,
        token: { access: user.access, refresh: user.refresh },
        user: user.user,
      }));
      navigate("/");
    }
  }, [formState]);
  const handleClick = (_id, _type) => {
    switch (step) {
      case 1: // Login Step
        if (_type === "apicall") {
          loginCall();
        } else {
          setStep(2);
        }
        return; // Exit early after handling step 1

      case 2: // Send OTP Step
        if (_type === "apicall") {
          sendOTP();
        } else {
          setStep(1);
        }
        return;

      case 3: // Validate OTP Step
        if (_type === "apicall") {
          validateOTP();
        } else {
          setStep(1);
        }
        return;

      case 4: // Reset Password Step
        if (_type === "apicall") {
          resetPassword();
        } else {
          setStep(1);
        }
        return;

      case 5: // Assuming a success/completion step
        setStep(1);
        return;

      default:
        console.error("Invalid step:", step);
    }
  };

  const CurrentComponent = useMemo(() => {
    // to render component based on current step
    dispatch({ type: "RESET" });
    const component = signUpStepsArray.find((item, index) => {
      return item.id === step;
    }).component;
    return component;
  }, [step]);

  const loginCall = async () => {
    // user login
    const postData = {
      name: name.value,
      email: email.value,
    };
    handleApiCall("signUp", postData, "SET_LOGGED_IN", dispatch);
  };

  const sendOTP = async () => {
    // generate otp
    const postData = {
      email: email.value,
    };
    handleApiCall(
      "sendOTP",
      postData,
      () => {
        setStep(3);
      },
      dispatch
    );
  };

  const validateOTP = async () => {
    // validating otp
    dispatch({ type: "SET_LOADING" });
    const postData = {
      email: email.value,
      code: otp.value,
    };
    handleApiCall(
      "validateOTP",
      postData,
      () => {
        setStep(4);
      },
      dispatch
    );
  };

  const resetPassword = async () => {
    // for resetting password
    dispatch({ type: "SET_LOADING" });
    const postData = {
      password: confirm_password.value,
    };
    handleApiCall(
      "resetPassword",
      postData,
      () => {
        setStep(5);
      },
      dispatch
    );
  };

  return (
    <div>
      <div className={styles.pageContainer}>
        <div className={styles.imageContainer}></div>
        <div className={styles.formContainer}>
          {signUpStepsArray.map((item, index) => {
            if (step === item.id) {
              return (
                <CurrentComponent
                  formState={formState}
                  {...fieldsData[item.name]}
                  key={index}
                  id={item.id}
                  handleClick={handleClick}
                  {...(item.id !== 5
                    ? { fields: item.fields, fieldsData }
                    : null)}
                />
              );
            } else return null;
          })}
        </div>
      </div>
      <PositionedSnackbar formState={formState} />
    </div>
  );
}

export default SignUp;
