import { useScrollTrigger } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Tooltip, // Correct Tooltip import
  ResponsiveContainer,
} from "recharts";
import InnerChart from "../InnerChart/InnerChart";

const style = {
  boxShadow: "0px 5px 15px 0px #27305014",
  borderRadius: "15px",
  padding: "10px ",
};

const CurvedBar = (props) => {
  const { fill, x, y, width, height } = props;
  const radius = Math.min(height / 2, width / 2);

  if (height === 0 || width === 0) return null;

  return (
    <g>
      <path
        d={`M${x},${y} 
                H${x + width - radius} 
                A${radius},${radius} 0 0 1 ${x + width},${y + radius} 
                V${y + height - radius} 
                A${radius},${radius} 0 0 1 ${x + width - radius},${y + height} 
                H${x} 
                V${y} 
                Z`}
        fill={fill}
      />
    </g>
  );
};

function VerticalChart({ data, text, width, Label, Chartdata = [] }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // debugger
    console.log("emljdfas329ueherhn");

    setOpen(!open);
  };
  const handleClose = () => {
    // debugger
    // setOpen(false);
  };
  return (
    <div style={style}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            fontSize: "24px",
            fontWeight: "600",
            margin: "0px",
            padding: "10px",
          }}
        >
          {text}
        </h3>{" "}
        {Chartdata?.length > 0 && (
          <div style={{ paddingRight: "20px", cursor: "pointer" }}>
            <KeyboardArrowDownIcon onClick={handleOpen} />
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height={data?.length * 60}>
        <ComposedChart
          layout="vertical"
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid
            stroke=" #f5f5f5"
            horizontal={false}
            vertical={true}
            strokeDasharray="4 4"
          />
          <XAxis
            type="number"
            axisLine={false}
            tickMargin={15}
            stroke="#8E8E93"
            fontSize={12}
            tickLine={false}
          />
          <YAxis
            dataKey="reason"
            stroke="#8E8E93"
            type="category"
            scale="point"
            tickLine={false}
            tickMargin={5}
            axisLine={{
              stroke: "#8E8E93",
              strokeWidth: 0.5,
            }}
            padding={{ top: 20, bottom: 20 }}
            width={width}
            fontSize={12}
          />
          <Tooltip
            cursor={false}
            formatter={(value) => `${Math.round(value)}`}
          />
          <Bar
            dataKey="percentage"
            barSize={30}
            fill="#4DD0E1"
            shape={<CurvedBar />}
          >
            {Label && (
              <LabelList
                dataKey="percentage"
                formatter={(value) =>
                  value > 0 ? `${Math.round(value)}%` : ""
                }
                position="insideRight"
                fill="#ffff"
                fontSize={12}
              />
            )}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
      {Chartdata.length > 0 && (
        <InnerChart
          open={open}
          handleClose={handleOpen}
          Chartdata={Chartdata}
          quitText={text}
        />
      )}
    </div>
  );
}

export default VerticalChart;
