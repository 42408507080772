import React, { useEffect } from "react";
import styles from "./AmbitionCard.module.css";
function AmbitionCard({data}) {

  const color= data.percentage < 50 ? "#F67D7D" : data.percentage < 80 ? "#FBD26A" : "#92F9C9"

  return (
    <div className={styles.container}>
      <p className={styles.percent} style={{color:color}} > {data.percentage}% </p>

      <p className={styles.desc}>
       {data.statement}
      </p>
    </div>
  );
}

export default AmbitionCard;
