import { Button } from "@mui/material";
import React from "react";

function SelectedSurvey({ handleOpen, data }) {
  return (
    <div>
      <div
        style={{
          marginTop: "20px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #4DD0E14D",
          padding: "10px",
        }}
      >
        <div>
          <p style={{ margin:"0px",fontSize:"14px" ,fontWeight:"400" }}>Selected Survey</p>
          <p style={{ fontWeight:"600",fontSize:"20px",margin:"0px" }}>{data?.title}</p>
        </div>
        <Button variant="text" onClick={handleOpen}>
          Change
        </Button>
      </div>
    </div>
  );
}

export default SelectedSurvey;
