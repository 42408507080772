import React, { useState } from "react";
import styles from "./ScoreCom.module.css";

import warningImg from "../../assets/componentAssest/warningImg.svg";
import scoreIMg from "../../assets/componentAssest/scoreIMg.png";
import PieChart from "../PieChart/PieChart";
function ScoreCom({ data, open, handleClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.imgCon}>
          <img src={data.img} />
        </div>
        <div className={styles.desc}>
          <p className={styles.text}>{data.name.toUpperCase()}</p>
          <img
            src={warningImg}
            className={styles.img}
            onClick={() => handleClick(data?.id)}
          />
        </div>
      </div>
      <div className={styles.progress}>
        <PieChart value={data.score} color={"#92F9C9"} size={60} />
      </div>
      {open && (
        <div className={styles.pop}>
          <p>
            {data.score}
            {data?.message}
          </p>
        </div>
      )}
    </div>
  );
}

export default ScoreCom;
