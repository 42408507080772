import React from "react";
import tickCircle from "../../assets/onboarding/tickCircle.png";
import styles from "../Onboarding.module.css";
import ImageryText from "../ImageryText/ImageryText";
import ControlledButton from "../../Components/ControlledButton/ControlledButton";

function SignUpStep5({ id, handleClick, formState, ...props }) {
  return (
    <div className={styles.stepContainer}>
      <ImageryText image={tickCircle} primaryText="Password reset successfully">
        <span>Your password has been successfully reset.</span>
        <br />
        <span>Click below to log in.</span>
      </ImageryText>
      <div className={styles.formSection}>
        <ControlledButton
          style={{ width: "100%", marginTop: "30px" }}
          onClick={() => {
            handleClick(id, "redirection");
          }}
        >
          Click here to login magically
        </ControlledButton>
      </div>
    </div>
  );
}

export default SignUpStep5;
