import React from "react";
import VerticalChart from "../VerticalChart/VerticalChart";

function MedianAgeChart({ ChartData }) {
  const data = ChartData?.slice(0, 5);

  const text = "Median Age";
  return (
    <div style={{ width: "100%" }}>
      <VerticalChart data={data} text={text} width={60} Label={true} />
    </div>
  );
}

export default MedianAgeChart;
