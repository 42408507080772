import React, { useContext, useEffect, useState } from "react";
import styles from "./YourRespondents.module.css";
import filterImg from "../../assets/componentAssest/filter.png";
import Filter from "../../Components/Filter/Filter";
import CustomDrawer from "../../Components/CustomDrawer/CustomDrawer";
import { Button, Skeleton } from "@mui/material";
import EmptyPage from "../../Components/EmptyPage/EmptyPage";
import Respondents from "../../assets/componentAssest/Respondents.svg";
import FunnelChart from "../../Components/FunnelChart/FunnelChart";
import CreateSurveyPop from "../../Components/CreateSurveyPop/CreateSurveyPop";
import { AppContext } from "../../ContextApi/AppContext/AppContext";
import { getApi } from "../../Utility/network";
import VerticalChart from "../../Components/VerticalChart/VerticalChart";
import MedianAgeChart from "../../Components/MedianAgeChart/MedianAgeChart";
import MedianTenure from "../../Components/MedianTenure/MedianTenure";

function YourRespondents() {
  const [open, setOpen] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const { contextState } = useContext(AppContext);
  const { selectedSurvey } = contextState;
  const [respondent, setRespondent] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState();
  const [filterValue, setFilterValue] = useState({
    department: [],
    seniority: [],
    gender: [],
    location: [],
  });
  const [dropDown, setDropDown] = useState({
    departmentValue: "",
    seniorityValue: "",
    genderValue: "",
    locationValue: "",
  });
  const reset = () => {
    setDropDown({
      departmentValue: "",
      seniorityValue: "",
      genderValue: "",
      locationValue: "",
    });
  };
  const handleClosePopUp = () => {
    setPopUp(!true);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    boxShadow: "0px 5px 15px 0px #27305014",
    borderRadius: "15px",
    padding: "10px ",
    width: "49%",
  };

  const getRespondedData = async (id, arr) => {
    if (!id) return;
    const params = {};

    if (arr) {
      let {
        departmentValue: department,
        genderValue: gender,
        locationValue: location,
        seniorityValue: seniority,
      } = arr;

      let arr1 = { department, gender, location, seniority };
      // Function to check if a value is an object
      function isObject(value) {
        return value;
      }

      // Create an empty object to store the URL parameters

      // Iterate over the variables and add them to the parameters object if they are objects
      for (const key in arr1) {
        if (isObject(arr1[key])) {
          params[key] = arr1[key];
        }
      }
    }
    const url = `survey/dashboard/respondents_analytics/${id}`; // Replace with your actual API endpoint
    const config = {
      params,
    };
    try {
      setLoading(true);
      const getResponse = await getApi(url, params);
      console.log(getResponse?.data, "dats");

      setRespondent(getResponse?.data);
      setLoading(false);
    } catch (error) {}
  };

  console.log(respondent, "respondent");
  const getDataFilter = async (id) => {
    if (!id) return;
    try {
      const getResponse = await getApi(
        `survey/dashboard/dropdown/${id}?filter_gender=true&filter_department=true&filter_seniority=true&filter_location=true`
      );
      console.log(getResponse, "response21212");

      let { departments, genders, seniority_levels, locations } =
        getResponse.data;
      departments = departments?.map((item, index) => {
        return { id: item, name: item };
      });
      seniority_levels = seniority_levels?.map((item, index) => {
        return { id: item, name: item };
      });
      genders = genders?.map((item, index) => {
        return { id: item, name: item };
      });
      locations = locations?.map((item, index) => {
        return { id: item, name: item };
      });
      setFilterValue({
        department: departments,
        seniority: seniority_levels,
        gender: genders,
        location: locations,
      });
    } catch (error) {}
  };
  console.log(filterValue, "value");

  useEffect(() => {
    if (selectedSurvey.id !== null) {
      getRespondedData(selectedSurvey?.id, dropDown);
      getDataFilter(selectedSurvey.id);
    }
  }, [selectedSurvey, dropDown]);
  const handleData = () => {
    getRespondedData(selectedSurvey?.id, dropDown);
    getDataFilter(selectedSurvey.id);
  };
  useEffect(() => {
    if (selectedSurvey.id !== null) {
      const timout = setTimeout(() => {
        const finalFilters = {};

        if (dropDown?.departmentValue) {
          const dept = dropDown?.departmentValue;

          finalFilters.department = dept;
        }

        if (dropDown?.seniorityValue) {
          const seniority = dropDown?.seniorityValue;

          finalFilters.seniority = seniority;
        }

        if (dropDown?.genderValue) {
          const gender = dropDown?.genderValue;

          finalFilters.gender = gender;
        }
        if (dropDown?.locationValue) {
          const location = dropDown?.locationValue;

          finalFilters.location = location;
        }

        // getTopData(selectedSurvey.id, finalFilters);
        // getData(selectedSurvey.id, finalFilters);
      }, 600);
      return () => clearTimeout(timout);
    }
  }, [selectedSurvey, dropDown]);

  const topCard = [
    { id: 1, value: respondent?.survey_details?.open_rate, name: " Open Rate" },
    {
      id: 2,
      value: respondent?.survey_details?.completion_rate,
      name: " Completion Rate",
    },
    {
      id: 3,
      value: respondent?.survey_details?.total_respondents,
      total: respondent?.survey_details?.total_invited,
      name: " Total respondents",
    },
  ];

  return (
    <>
      {selectedSurvey.id ? (
        <div>
          <div className={styles.header}>
            <h3 className={styles.heading}>Know Your Respondents</h3>
            <div className={styles.filter} onClick={handleOpen}>
              <img src={filterImg} />
            </div>
          </div>
          <div className={styles.selectSurvey}>
            <div>
              <p className={styles.label}>Selected Survey</p>
              <p className={styles.surveyName}>{selectedSurvey?.title}</p>
            </div>
            <Button
              variant="text"
              onClick={() => {
                setPopUp(true);
              }}
            >
              Change
            </Button>
          </div>
          {popUp && (
            <CreateSurveyPop open={popUp} handleClose={handleClosePopUp} />
          )}
          <CustomDrawer
            open={open}
            anchor={"right"}
            width={"25%"}
            onClose={handleClose}
          >
            <Filter
              close={handleClose}
              filterData={filter}
              setDropDown={setDropDown}
              filterValue={filterValue}
              dropDown={dropDown}
              callBack={handleData}
              reset={reset}
            />
          </CustomDrawer>
          <div className={styles.top}>
            {topCard.map((item) => {
              return (
                <>
                  {loading ? (
                    <Skeleton variant="rounded" width={"30%"} height={100} />
                  ) : (
                    <div className={styles.boxContainer}>
                      <p className={styles.name}> {item.name.toUpperCase()}</p>
                      <p className={styles.value}>
                        {item.value}
                        {item.total ? (
                          <span> Out of {item.total}</span>
                        ) : (
                          <div></div>
                        )}
                      </p>
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div className={styles.chartCon}>
            {loading ? (
              <Skeleton variant="rounded" width={"45%"} height={500} />
            ) : (
              <div style={style}>
                <h3
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    margin: "0px",
                    padding: "10px",
                  }}
                >
                  Employee Funnel
                </h3>
                {respondent?.employee_funnel.length > 0 && (
                  <FunnelChart funnelData={respondent?.employee_funnel} />
                )}

                <div className={styles.funnelData}>
                  {respondent?.employee_funnel?.map((item) => {
                    return (
                      <div className={styles.info}>
                        <p
                          className={styles.funnelValue}
                          style={{ fontWeight: "900", fontSize: "20px" }}
                        >
                          {item.count}
                        </p>
                        <p className={styles.funnelName}>{item.reason}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className={styles.leftchart}>
              {loading ? (
                <Skeleton variant="rounded" width={"90%"} height={300} />
              ) : (
                <MedianAgeChart
                  ChartData={respondent?.median_age}
                  tooltip={true}
                />
              )}
              {loading ? (
                <Skeleton variant="rounded" width={"90%"} height={300} />
              ) : (
                <MedianTenure ChartData={respondent?.median_tenure} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <EmptyPage
            headerText={"No results found"}
            descText={
              "We are waiting for participants to complete the Diagnostics, please check back later"
            }
            pageHeading={"Know Your Respondents"}
            image={Respondents}
          />
          {popUp && (
            <CreateSurveyPop open={popUp} handleClose={handleClosePopUp} />
          )}
        </div>
      )}
    </>
  );
}

export default YourRespondents;
const funnelData = [
  { id: 1, value: "501", name: "Entry-level Professional" },
  { id: 2, value: "501", name: "Vice President" },
  { id: 3, value: "240", name: "Manager" },
  { id: 4, value: "240", name: "Senior Vice President" },
  { id: 5, value: "152", name: "Senior Manager / Director" },
  { id: 6, value: "152", name: "C-Suite" },
];
