import React from "react";
import styles from "../Onboarding.module.css";
import samalogo from "../../assets/onboarding/samalogo.svg";
import RegisterFields from "../RegisterFields/RegisterFields";
import ControlledCheckbox from "../../Components/ControlledCheckBox/ControlledCheckBox";
import ControlledButton from "../../Components/ControlledButton/ControlledButton";
import ImageryText from "../ImageryText/ImageryText";
import CircularLoading from "../../Components/CircularLoading/CircularLoading";
import { PRIMARYCOLOR } from "../../Utility/constants";

function SignUpStep1({
  id,
  fields,
  formState,
  fieldsData,
  handleClick,
  ...props
}) {
  return (
    <div className={styles.stepContainer}>
      <ImageryText
        image={samalogo}
        primaryText="Log in to your account."
        showBorder={false}
      >
        <span>Enter your email address and password to log in.</span>
      </ImageryText>
      <div className={styles.formSection}>
        {fields.map((item, index) => {
          return (
            <div key={index}>
              <p style={{ textTransform: "capitalize" }}>{item.label}</p>
              <RegisterFields
                key={index}
                name={item.name}
                placeholder={item.placeholder}
                size="small"
                {...fieldsData[item.name]}
                style={{ width: "100%" }}
                type={item.type}
              />
            </div>
          );
        })}
        <p style={{ marginTop: "8px", color: "#ef5350" }}>{formState.error}</p>

        <ControlledButton
          style={{ width: "100%", marginTop: "30px" }}
          onClick={() => {
            handleClick(id, "apicall");
          }}
          disabled={formState.loading}
        >
          {formState.loading && (
            <CircularLoading size={20} style={{ marginRight: "15px" }} />
          )}{" "}
          Login
        </ControlledButton>

        <p style={{ color: "#1D243380" }}>
          Already have an account?{" "}
          <span
            style={{
              color: "#4DD0E1",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {}}
          >
            Login Here
          </span>
        </p>
      </div>
    </div>
  );
}

export default SignUpStep1;
